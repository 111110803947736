import LayerGroup from "ol/layer/Group";
import TileGrid from "ol/tilegrid/TileGrid";
import TileLayer from "ol/layer/Tile";
import { Feature, Map, View } from "ol";
import { XYZ } from "ol/source";
import { createXYZ } from "ol/tilegrid";
import VectorTileSource from "ol/source/VectorTile";
import MVTFormat from "ol/format/MVT";
import VectorTileLayer from "ol/layer/VectorTile";
import { getCenter } from "ol/extent";
import { DATA_DIR_PREFIX } from "../utils/constants";
import store from "../store";
import router from "../router";
import { applyStyle } from "ol-mapbox-style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Point } from "ol/geom";
import { Style, Icon, Text, Stroke } from "ol/style";

/**
 * @typedef {Object} LegendItem
 * @property {string} farbe
 * @property {Array<number>} rgb
 * @property {string} bezeichnung
 */

/**
 * @typedef {Object} RasterLayerData
 * @property {string} name
 * @property {string} url
 * @property {number} tileSize
 * @property {number} minZoom
 * @property {number} maxZoom
 * @property {string} [legendUrl]
 * @property {Array<LegendItem>} [legendItems]
 */

const esriToken =
  "AAPK90a6fb02892b42a982f3a1c659979288JfQmnVZhFssmDKnTPaRqZ9Z_F3frPMGbgPE_pE8nQ9bgtvmtMZp8ba49lv5KtoJ2";

const basemapAttribution =
  '<a href="http://www.basemap.at">basemap.at</a> &copy; <a href="http://creativecommons.org/licenses/by/3.0/at/">CC BY 3.0 AT</a>';
export const basemapExtent = [977650, 5838030, 1913530, 6281290];

const basemapTilegrid = new TileGrid({
  extent: basemapExtent,
  origin: [-20037508.3428, 20037508.3428],
  resolutions: createXYZ({
    maxZoom: 18
  }).getResolutions()
});

const token = "AAPKc516ac0ec97441a887dfe8a9c9dd6eff4dIVcscagpXCXw0QDekspyAZA0unpachzU_W_DWnEU9Xxr8WsJLAx1hs1LxB2Ro4";
function transformRequest(url, type) {
  if (type === "Source") {
    return new Request(url.replace("/VectorTileServer", "/VectorTileServer/"));
  }
}
const esriLayer = new VectorTileLayer({
  className: "esri",
  declutter: true,
  maxZoom: 17
});
applyStyle(
  esriLayer,
  "https://basemaps-api.arcgis.com/arcgis/rest/services/styles/edf23db1cc1f4fc0813edc92be87ff85?type=style&token=" +
    token,
  "",
  { transformRequest }
).then(() => {
  esriLayer
    .getSource()
    .setAttributions(
      'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
        'rest/services/World_Topo_Map/MapServer">Esri, HERE, Garmin, FAO, NOAA, USGS, © OpenStreetMap contributors, and the GIS User Community</a>'
    );
});

const orthoImageLayer = new TileLayer({
  source: new XYZ({
    attributions: basemapAttribution,
    crossOrigin: "anonymous",
    tileGrid: basemapTilegrid,
    url: "https://mapsneu.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpg"
  })
});

const orthoLabelsLayer = new TileLayer({
  source: new XYZ({
    attributions: basemapAttribution,
    crossOrigin: "anonymous",
    tileGrid: basemapTilegrid,
    url: "https://mapsneu.wien.gv.at/basemap/bmapoverlay/normal/google3857/{z}/{y}/{x}.png"
  })
});

const dataSource = new VectorTileSource({
  maxZoom: 11,
  format: new MVTFormat(),
  url: "./data/tiles/{z}/{x}/{y}.pbf",
  attributions: "BEV, Stand 2021"
});

/**
 * includes borders and labels
 */
const esriLabelsLayer = new VectorTileLayer({
  declutter: true
});
applyStyle(
  esriLabelsLayer,
  "https://basemaps-api.arcgis.com/arcgis/rest/services/styles/7f208f66475c485194fc5b2c29792c43?type=style&token=" +
    esriToken,
  "",
  { transformRequest }
);

const esriLabelsLightLayer = new VectorTileLayer({
  declutter: true
});
applyStyle(
  esriLabelsLightLayer,
  "https://basemaps-api.arcgis.com/arcgis/rest/services/styles/0a0ab4c103d341ee809dde93ac5abe56?type=style&token=" +
    esriToken,
  "",
  { transformRequest }
);

const esriHillshadeLayer = new TileLayer({
  source: new XYZ({
    zDirection: 1,
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/Elevation/World_Hillshade/MapServer/tile/{z}/{y}/{x}",
    attributions:
      'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
      'rest/services/Elevation/World_Hillshade/MapServer">Esri, HERE, Garmin, FAO, NOAA, USGS, Intermap, METI, © OpenStreetMap contributors, and the GIS User Community</a>'
  })
});

const esriHillshadeAndLabelsLayer = new LayerGroup({
  layers: [esriHillshadeLayer, esriLabelsLayer]
});

const baseLayers = {
  orthoImage: () => orthoImageLayer,
  orthoLabels: () => orthoLabelsLayer,
  esriLabels: () => (router.currentRoute.params.datamode == "1" ? esriLabelsLayer : esriLabelsLightLayer),
  esriHillshade: () => esriHillshadeLayer,
  esriHillshadeAndLabels: () => esriHillshadeAndLabelsLayer,
  esri: () => esriLayer,
  empty: () => new TileLayer()
};

/**
 * @typedef {keyof baseLayers} BaseLayer
 */

export const regionDataLayer = new VectorTileLayer({
  opacity: 0.8,
  visible: true,
  style: () => {}
});

export const dataLayerGroup = new LayerGroup({
  layers: [regionDataLayer]
});

const photonStyle = new Style({
  image: new Icon({
    src: "photonMarker.png",
    crossOrigin: "anonymous",
    opacity: 1,
    scale: 0.05,
    anchor: [0.5, 1]
  }),
  text: new Text({
    text: "",
    offsetY: 12,
    font: "14px sans-serif",
    stroke: new Stroke({
      width: 4,
      color: "rgba(255, 255, 255, 0.4)"
    })
  })
});

/** @type {VectorLayer<VectorSource<Point>>} */
export const photonLayer = new VectorLayer({
  zIndex: 1000000,
  //@ts-ignore
  name: "photon",
  source: new VectorSource({
    features: [
      new Feature({
        geometry: new Point([NaN, NaN])
      })
    ]
  }),
  style: (feature) => {
    photonStyle.getText().setText(feature.get("text"));
    return photonStyle;
  }
});

const viewExtent = [1060650, 5838030, 1913000, 6281290];
export const map = new Map({
  layers: [baseLayers.esri(), dataLayerGroup, baseLayers.empty(), photonLayer],
  view: new View({
    extent: viewExtent,
    showFullExtent: true,
    center: getCenter(viewExtent),
    constrainOnlyCenter: true,
    zoom: 0,
    maxZoom: 11,
    enableRotation: false
  })
});
map.on("change:size", (e) => {
  const element = e.target.getTargetElement();
  const [width, height] = [element.clientWidth, element.clientHeight];
  let minZoom = 5;
  if (width > 700 && height > 470) {
    minZoom = 7;
  } else if (width > 500 && height > 300) {
    minZoom = 6;
  }
  map.getView().setMinZoom(minZoom);
});

regionDataLayer.setSource(dataSource);

export function setVectorLayer() {
  dataLayerGroup.getLayers().setAt(0, regionDataLayer);
  store.dispatch("baseLayer", { below: "esri", above: "empty" });
}

export function zoomToFullExtent(duration = 250) {
  map.getView().fit(viewExtent, {
    padding: [50, 50, 50, 50],
    duration
  });
}

/**
 * @param {RasterLayerData} layerData
 */
export function setRasterLayer(layerData) {
  const routeParam = `m${layerData.name}`;
  if (router.currentRoute.params.attribute !== routeParam) {
    router.push({ params: { attribute: routeParam } });
  }
  store.dispatch("rasterLayer", layerData);
  const layer = new TileLayer({
    source: new XYZ({
      url: DATA_DIR_PREFIX + layerData.url,
      minZoom: layerData.minZoom,
      maxZoom: layerData.maxZoom,
      tileSize: layerData.tileSize,
      transition: 0,
      interpolate: false,
      attributions: ["BFW"]
    })
  });
  dataLayerGroup.getLayers().setAt(0, layer);
  store.dispatch("baseLayer", { below: "esriHillshade", above: "esriLabels" });
}

export function setEmptyLayer() {
  dataLayerGroup.getLayers().setAt(0, regionDataLayer);
  store.dispatch("baseLayer", { below: "empty", above: "empty" });
}

/**
 * @param {BaseLayer} below
 * @param {BaseLayer} above
 */
export function setBaseLayer(below, above) {
  map.getLayers().setAt(0, baseLayers[below]());
  map.getLayers().setAt(2, baseLayers[above]());
}
