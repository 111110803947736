import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (
    Number(_vm.$route.params.datamode) == 0 &&
    _vm.$store.state.selectedRegion &&
    typeof _vm.$store.state.selectedRegion.lbfi != 'undefined'
  )?_c('div',{staticClass:"attributeInspector",class:{ mobile: _vm.$store.state.isMobile }},[_c(VCard,{attrs:{"flat":"","tile":""}},[_c(VRow,{staticClass:"smallUpper",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"10"}},[_c('span',[_vm._v(_vm._s(_vm.$store.state.selectedRegion.name))])]),_c(VCol,{attrs:{"cols":"2","align":"right"}},[_c(VBtn,{staticClass:"ma-0",attrs:{"icon":"","small":""},on:{"click":_vm.closePanel}},[_c(VIcon,{staticClass:"ma-0",attrs:{"color":"#455a4e"}},[_vm._v("mdi-close")])],1)],1)],1),(!_vm.prozentwertfehler.datenfehler)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"smallLower",attrs:{"cols":"12"}},[(_vm.prozentwertfehler.wert)?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.prozentwertfehler.wert)+" "+_vm._s(_vm.prozentwertfehler.einheit))]),_vm._v(" "),(_vm.prozentwertfehler.fehler)?_c('span',[_vm._v("("+_vm._s(_vm.prozentwertfehler.fehler)+")")]):_vm._e()]):_vm._e(),(_vm.prozentwertfehler.prozent)?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.prozentwertfehler.prozent)+"%")]),_vm._v(" bezogen auf "+_vm._s(_vm.bezugsGroesse)+" ")]):_vm._e()])],1):_vm._e(),(_vm.prozentwertfehler.datenfehler)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"smallLower",attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.prozentwertfehler.datenfehler))])],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }