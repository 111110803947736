<template>
  <div class="wissensWertes">
    <div v-if="anzeigeBild" class="imgOverlay" @click="anzeigeBild = null">
      <v-icon dark large>mdi-close</v-icon>
      <v-img :src="anzeigeBild" width="100%" height="100%" contain class="centerImage" />
    </div>
    <h2 class="mt-5 mb-8">Wissenswertes</h2>

    <v-row no-gutters>
      <v-col cols="0" sm="0" lg="1" />
      <v-col cols="12" sm="12" lg="10">
        <v-row no-gutters>
          <v-col cols="12" md="6" lg="4" class="px-8 pb-6">
            <div
              class="infoImage"
              @click="anzeigeBild = `./data/landingpage/wissenswertes_${language}_1.jpg`"
              :style="`background-image: url(./data/landingpage/wissenswertes_${language}_1.jpg)`"
            />
            <div
              v-html="$store.state.landingpagetexte ? $store.state.landingpagetexte['wissenswertes_1'][language] : ''"
            />
          </v-col>
          <v-col cols="12" md="6" lg="4" class="px-8 pb-6">
            <div
              class="infoImage"
              @click="anzeigeBild = `./data/landingpage/wissenswertes_${language}_2.jpg`"
              :style="`background-image: url(./data/landingpage/wissenswertes_${language}_2.jpg)`"
            />
            <div
              v-html="$store.state.landingpagetexte ? $store.state.landingpagetexte['wissenswertes_2'][language] : ''"
            />
          </v-col>
          <v-col cols="12" md="6" lg="4" class="px-8 pb-6">
            <div
              class="infoImage"
              @click="anzeigeBild = `./data/landingpage/wissenswertes_${language}_3.jpg`"
              :style="`background-image: url(./data/landingpage/wissenswertes_${language}_3.jpg)`"
            />
            <div
              v-html="$store.state.landingpagetexte ? $store.state.landingpagetexte['wissenswertes_3'][language] : ''"
            />
          </v-col>
          <v-col cols="12" md="6" lg="4" class="px-8 pb-6">
            <div
              class="infoImage"
              @click="anzeigeBild = `./data/landingpage/wissenswertes_${language}_4.jpg`"
              :style="`background-image: url(./data/landingpage/wissenswertes_${language}_4.jpg)`"
            />
            <div
              v-html="$store.state.landingpagetexte ? $store.state.landingpagetexte['wissenswertes_4'][language] : ''"
            />
          </v-col>
          <v-col cols="12" md="6" lg="4" class="px-8 pb-6">
            <div
              class="infoImage"
              @click="anzeigeBild = `./data/landingpage/wissenswertes_${language}_5.jpg`"
              :style="`background-image: url(./data/landingpage/wissenswertes_${language}_5.jpg)`"
            />
            <div
              v-html="$store.state.landingpagetexte ? $store.state.landingpagetexte['wissenswertes_5'][language] : ''"
            />
          </v-col>
          <v-col cols="12" md="6" lg="4" class="px-8 pb-6">
            <div
              class="infoImage"
              @click="anzeigeBild = `./data/landingpage/wissenswertes_${language}_6.jpg`"
              :style="`background-image: url(./data/landingpage/wissenswertes_${language}_6.jpg)`"
            />
            <div
              v-html="$store.state.landingpagetexte ? $store.state.landingpagetexte['wissenswertes_6'][language] : ''"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="0" sm="0" lg="1" />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Wissenswertes",
  data: () => ({
    anzeigeBild: null
  }),
  mounted() {},
  updated() {},
  watch: {},
  computed: {
    language() {
      return this.$route.params.lang ? this.$route.params.lang : "de";
    }
  },
  methods: {}
};
</script>

<style>
p small {
  font-size: 11px;
}
</style>
<style scoped>
.wissensWertes {
  color: rgb(69, 89, 79);
  font-size: 13px;
}

.wissensWertes .v-image {
  cursor: pointer;
}

.wissensWertes h2 {
  font-weight: 300;
  font-size: 3em;
  text-align: center;
}

.wissensWertes h4 {
  font-weight: 300;
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px;
}
.wissensWertes a {
  color: #447681;
  text-decoration: none;
}

.imgOverlay {
  position: fixed;
  left: 0px;
  top: 100px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: calc(100vh - 100px);
  padding: 30px;
  z-index: 5;
}

.imgOverlay .v-icon {
  position: absolute;
  right: 15px;
  top: 30px;
}

.centerImage {
  margin-left: auto;
  margin-right: auto;
}

.infoImage {
  cursor: pointer;
  height: 250px;
  width: 100%;
  background-size: contain;
  background-position: center bottom;
  margin-bottom: 15px;
}
</style>
