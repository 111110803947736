<template>
  <div class="searchField" style="position: relative">
    <v-text-field
      :class="{ mobile: $store.state.isMobile }"
      ref="textField"
      v-model="searchString"
      class="searchPlaces"
      placeholder="Suche"
      hide-details
      :prepend-inner-icon="searchString ? '' : 'mdi-magnify'"
      single-line
      clearable
      flat
      solo
      dense
      outlined
      rounded
      color="#455a4e"
      :loading="loadingPhotonData"
      aria-label="Suche"
      @keyup="updateSearchString"
    />
    <v-card
      v-if="results.length"
      class="mt-0 py-2 resultBox"
      :rounded="false"
      :class="{ mobile: $store.state.isMobile }"
    >
      <v-card v-if="error" class="pa-1 pl-3" width="100%" :rounded="false">
        {{ error }}
      </v-card>
      <div v-else>
        <div v-for="(result, index) in photonResults" :key="index" class="resultRow" @click="goToResult(result)">
          <v-icon class="mr-2" small>mdi-map-marker</v-icon>
          <span>{{ getNameForResult(result) }}</span>
        </div>
        <div
          v-for="(result, index) in metaDataResults"
          :key="index + '_meta'"
          class="resultRow"
          @click="goToMetaResult(result)"
        >
          <v-icon class="mr-2" small>mdi-filter</v-icon>
          <span>{{ result.item.name }}</span>
        </div>
        <div
          v-for="(result, index) in expertDataResults"
          :key="index + '_expert'"
          class="resultRow"
          @click="goToExpertResult(result)"
        >
          <v-icon class="mr-2" small>mdi-filter</v-icon>
          <span>{{ result.item.name }}</span>
        </div>

        <div
          v-for="(result, index) in glossarDataResults"
          :key="index + '_glossar'"
          class="resultRow"
          @click="goToGlossarResult(result.item)"
        >
          <v-icon class="mr-2" small>mdi-information</v-icon>
          <span>{{ result.item }}</span>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { debounce } from "debounce";
import { applyTransform, getArea, boundingExtent } from "ol/src/extent";
import { getTransform } from "ol/src/proj";
import { basemapExtent, map, photonLayer } from "../mixins/map";
import Fuse from "fuse.js";
import { fromLonLat, toLonLat } from "ol/proj";

let expertDataFuse, metaDataFuse, glossarDataFuse;

export default {
  data: () => ({
    searchString: "",
    loadingPhotonData: false,
    results: []
  }),
  computed: {
    error() {
      return (this.results.length && this.results[0].error) || null;
    },
    photonResults() {
      return this.results.filter((r) => r.type === "photon");
    },
    expertDataResults() {
      return this.results.filter((r) => r.type === "expertData");
    },
    metaDataResults() {
      return this.results.filter((r) => r.type === "metaData");
    },
    glossarDataResults() {
      return this.results.filter((r) => r.type === "glossarData");
    }
  },
  watch: {
    "$store.state": {
      deep: true,
      handler() {
        this.clearPhotonLayer();
      }
    },
    "$store.state.periode"() {
      this.initSearch();
    },
    "$store.state.expertdata"() {
      this.initSearch();
    },
    "$store.state.metadata"() {
      this.initSearch();
    }
  },
  created() {
    this.photonSearch = debounce(this.photonSearch, 500);
  },
  mounted() {
    this.initSearch();
  },
  methods: {
    initSearch() {
      const expertDataArray = [];
      // filter the expertData to only display the highest Hierarchy
      this.$store.state.expertdata[this.$store.state.periode].filter(this.filterLowerHierarchies).forEach((d) => {
        const hierarchy = d.Hierarchie_3 ? "Hierarchie_3" : d.Hierarchie_2 ? "Hierarchie_2" : "Hierarchie_1";
        const item = {
          level: hierarchy,
          item: d,
          searchString: d[hierarchy]
        };
        item.name = this.getNameForExpertResult(item);
        if (expertDataArray.find((o) => o.name === item.name)) {
          item.name = item.name + ` (${d.Einheit})`;
        }
        expertDataArray.push(Object.assign({}, item));
      });

      // there are some references on the expertDataArray somewhere in the app
      // this creates infinite update loops
      // therefore we make a simple clone without references here
      const fixedExpertDataArray = expertDataArray.map((i) => {
        return {
          item: {
            Kodierung_ID: i.item.Kodierung_ID
          },
          level: i.level,
          name: i.name,
          searchString: i.searchString
        };
      });
      expertDataFuse = new Fuse(fixedExpertDataArray, {
        includeScore: true,
        shouldSort: true,
        threshold: 0.2,
        findAllMatches: true,
        keys: ["searchString"]
      });

      const metaDataArray = [];
      this.$store.state.metadata[this.$store.state.periode].filter(this.filterLowerHierarchies).forEach((d) => {
        const hierarchy = d.Hierarchie_3 ? "Hierarchie_3" : d.Hierarchie_2 ? "Hierarchie_2" : "Hierarchie_1";
        const item = {
          level: hierarchy,
          item: d,
          searchString: d[hierarchy]
        };
        item.name = this.getNameForExpertResult(item);
        if (metaDataArray.find((o) => o.name === item.name)) {
          item.name = item.name + ` (${d.Einheit})`;
        }
        metaDataArray.push(item);
      });
      metaDataFuse = new Fuse(metaDataArray, {
        includeScore: true,
        shouldSort: true,
        threshold: 0.2,
        findAllMatches: true,
        keys: ["searchString"]
      });
      const glossarDataArray = [];
      this.$store.state.glossar.forEach((d) => {
        glossarDataArray.push(d.Begriff);
      });
      glossarDataFuse = new Fuse(glossarDataArray, {
        includeScore: true,
        shouldSort: true,
        threshold: 0.2,
        findAllMatches: true
      });
    },

    /**
     * callback function for array-filter
     * returns true only if there is no other other object of an higher hierarchy
     */
    filterLowerHierarchies(o, index, allObjects) {
      if (
        parseInt(o.Anzeigen) == 0 &&
        parseInt(o.Bund) == 0 &&
        parseInt(o.Bundesland) == 0 &&
        (parseInt(o.BFI) == 0 || o.BFI === "")
      ) {
        return false;
      }
      let hasLowerHierarchy = false;
      if (o.Hierarchie_2 === "") {
        hasLowerHierarchy =
          allObjects.filter((h2Object) => {
            h2Object.Hierarchie_1 === o.Hierarchie_1;
          }).length > 1;
      } else if (o.Hierarchie_3 === "") {
        hasLowerHierarchy =
          allObjects.filter((h3Object) => {
            h3Object.Hierarchie_1 === o.Hierarchie_1 && h3Object.Hierarchie_2 === o.Hierarchie_2;
          }).length >= 1;
      }
      return !hasLowerHierarchy;
    },
    /**
     * adds a given search object, if not already exising in the array
     * (to avoid duplicate entries in the fuse search array)
     * changes the existing array in place
     */
    addSearchObjectIfMissing(searchObject, existingArray) {
      const hasEntry = existingArray.find((o) => {
        return o.level === searchObject.level && o.name === searchObject.name;
      });
      if (!hasEntry) {
        existingArray.push(searchObject);
      }
    },
    clearPhotonLayer() {
      photonLayer.getSource().getFeatures()[0].getGeometry().setCoordinates([NaN, NaN]);
    },
    async photonSearch() {
      const wgsExtent = applyTransform(basemapExtent, getTransform("EPSG:3857", "EPSG:4326"));
      const bbox = wgsExtent.join(",");

      this.loadingPhotonData = true;
      const [lon, lat] = toLonLat(map.getView().getCenter());
      const photonString = `https://photon.komoot.io/api/?q=${this.searchString}&bbox=${bbox}&lon=${lon}&lat=${lat}&lang=de&osm_tag=place&osm_tag=boundary&osm_tag=natural&limit=20`;
      try {
        const response = await fetch(photonString);
        const data = await response.json();
        data.features.forEach((f) => (f.type = "photon"));
        const photonResults = data.features.filter((f) => f.properties.countrycode === "AT");
        this.results = [];
        if (photonResults.length) {
          this.removeDuplicates(photonResults);
          if (photonResults.length > 5) {
            photonResults.length = 5;
          }
          this.results.push(...photonResults);
        }
        const dataMode = Number(this.$route.params.datamode);
        const metaDataResults = dataMode === 0 ? metaDataFuse.search(this.searchString) : [];
        if (metaDataResults.length > 3) {
          metaDataResults.length = 3;
        }
        const expertDataResults = dataMode === 2 ? expertDataFuse.search(this.searchString) : [];
        if (expertDataResults.length > 3) {
          expertDataResults.length = 3;
        }
        const glossarDataResults = glossarDataFuse.search(this.searchString);
        if (glossarDataResults.length > 3) {
          glossarDataResults.length = 3;
        }
        metaDataResults.forEach((r) => (r.type = "metaData"));
        expertDataResults.forEach((r) => (r.type = "expertData"));
        glossarDataResults.forEach((r) => (r.type = "glossarData"));
        if (metaDataResults.length) {
          this.results.push(...metaDataResults);
        }
        if (expertDataResults.length) {
          this.results.push(...expertDataResults);
        }
        this.results.push(...glossarDataResults);
        this.loadingPhotonData = false;
      } catch (err) {
        this.results = [{ error: "Fehler bei der Ortssuche" }];
        this.loadingPhotonData = false;
      }
    },

    goToResult(result) {
      let area = 0;
      let extent = result.properties.extent;
      const resultText = this.getNameForResult(result);
      this.results.length = 0;
      this.searchString = resultText;

      if (!extent) {
        extent = boundingExtent([result.geometry.coordinates]);
      }
      const mercatorExtent = applyTransform(extent, getTransform("EPSG:4326", "EPSG:3857"));
      area = getArea(mercatorExtent);
      // if area of extent is above minimum size, treat the feature as an area
      if (area > 1000000) {
        // 1 km^2
        const rightShift = mercatorExtent[2] - mercatorExtent[0];
        mercatorExtent[0] -= rightShift;
      }
      map.getView().fit(mercatorExtent, {
        padding: [50, 50, 50, 50],
        duration: 250,
        maxZoom: 10
      });
      const photonFeature = photonLayer.getSource().getFeatures()[0];
      photonFeature.getGeometry().setCoordinates(fromLonLat(result.geometry.coordinates));
      photonFeature.set("text", resultText);
      return;
    },

    getNameForExpertResult(result) {
      if (result.level === "Hierarchie_1") {
        return `${result.item.Hierarchie_1}`;
      } else if (result.level === "Hierarchie_2") {
        return `${result.item.Hierarchie_1} | ${result.item.Hierarchie_2}`;
      } else {
        return `${result.item.Hierarchie_1} | ${result.item.Hierarchie_2} | ${result.item.Hierarchie_3}`;
      }
    },

    goToExpertResult(result) {
      this.$router.push({ params: { attribute: `t${result.item.item.Kodierung_ID}` } });
      this.results.length = 0;
      this.searchString = "";
    },
    goToMetaResult(result) {
      this.$router.push({ params: { attribute: `r${result.item.item.Kodierung_ID}` } });
      this.results.length = 0;
      this.searchString = "";
    },
    goToGlossarResult(begriff) {
      this.results.length = 0;
      this.searchString = "";
      this.$store.dispatch("glossarShow", begriff);
    },

    /**
     * removes results that would cause duplicate names
     * these most commonly are real duplicates, like duplicate street names for the
     * same street
     * changes the Array in place
     */
    removeDuplicates(resultArray) {
      const usedDisplayNames = [];
      for (let i = resultArray.length - 1; i >= 0; i--) {
        const name = this.getNameForResult(resultArray[i]);
        if (!name || usedDisplayNames.includes(name)) {
          resultArray.splice(i, 1);
        } else {
          usedDisplayNames.push(name);
        }
      }
      if (resultArray.length > 10) {
        resultArray.length = 10;
      }
    },

    getNameForResult(result) {
      const props = result.properties;
      if (props.name) {
        if (props.type === "region") {
          return `${props.name} (Region)`;
        } else if (props.city || props.district || props.county) {
          return `${props.name} (${props.city || props.district || props.county})`;
        } else {
          return props.name;
        }
      }
    },

    updateSearchString() {
      if (this.searchString) {
        this.photonSearch();
      } else {
        this.loadingPhotonData = false;
        this.results.length = 0;
        this.clearPhotonLayer();
      }
    }
  }
};
</script>

<style scoped>
.searchPlaces {
  width: calc(100% - 100px);
  margin-top: 10px;
  background-color: white;
  font-size: 14px;
  font-weight: normal;
}
.searchPlaces.mobile {
  width: calc(100% - 20px);
}
.searchField {
  margin-top: 9px;
  max-width: 700px;
  width: 100%;
}

.resultBox {
  width: calc(100% - 140px);
  left: 70px;
  top: 39px;
  position: absolute;
  max-height: calc(100vh - 200px);
  overflow: auto;
  z-index: 2000;
  box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.3);
}

.resultBox.mobile {
  position: fixed;
  width: 100vw;
  top: 58px;
  left: 0px;
  max-height: calc(100vh - 100px);
}

.resultBox.v-sheet.v-card {
  border-radius: 0px;
  border: 1px solid #455a4e;
}

.resultRow {
  text-align: left;
  font-weight: 300;
  font-size: 15px;
  text-transform: none;
  line-height: 34px;
  letter-spacing: normal;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid white;
}

.resultRow:hover {
  border-bottom: 1px solid #455a4e;
}

.resultRow span {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 40px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.resultRow .fullWidth {
  width: 100%;
}

.raised {
  z-index: 100;
}
</style>
