import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.state.contactShow)?_c('div',{staticClass:"contactScreen",class:{ onStart: typeof _vm.$route.params.datamode == 'undefined', mobile: _vm.$store.state.isMobile }},[_c('div',{staticClass:"contactViewer"},[_c(VRow,{staticClass:"pa-0 ma-0 contactNavi",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pt-2",attrs:{"cols":"10"}}),_c(VCol,{staticClass:"pt-2",attrs:{"cols":"2","align":"right"}},[_c(VIcon,{staticClass:"mr-2",attrs:{"title":"Schließen"},on:{"click":function($event){_vm.$store.state.contactShow = false}}},[_vm._v("mdi-close")])],1)],1),_c('div',{ref:"innerViewer",staticClass:"innerViewer pa-6"},[_c('h1',[_vm._v("Kontakt")]),_c('h2',[_vm._v("Leitung der Österreichischen Waldinventur:")]),_vm._m(0),_c('h2',[_vm._v("Technische Umsetzung und Kartographie:")]),_vm._m(1),_c('h2',[_vm._v("Systembetreuung:")]),_vm._m(2)])],1)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Klemens Schadauer ("),_c('a',{attrs:{"href":"mailto:klemens.schadauer@bfw.gv.at"}},[_vm._v("klemens.schadauer@bfw.gv.at")]),_vm._v(")"),_c('br'),_vm._v(" Alexandra Freudenschuß ("),_c('a',{attrs:{"href":"alexandra.freudenschuss@bfw.gv.at"}},[_vm._v("alexandra.freudenschuss@bfw.gv.at")]),_vm._v(") ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Thomas Öberseder"),_c('br'),_vm._v(" Christoph Bauerhansl ("),_c('a',{attrs:{"href":"mailto:christoph.bauerhansl@bfw.gv.at"}},[_vm._v("christoph.bauerhansl@bfw.gv.at")]),_vm._v(")"),_c('br'),_vm._v(" Gernot Felfernig ("),_c('a',{attrs:{"href":"mailto:gernot.felfernig@bfw.gv.at"}},[_vm._v("gernot.felfernig@bfw.gv.at")]),_vm._v(") ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Heimo Schaffer ("),_c('a',{attrs:{"href":"mailto:heimo.schaffer@bfw.gv.at"}},[_vm._v("heimo.schaffer@bfw.gv.at")]),_vm._v(")")])
}]

export { render, staticRenderFns }