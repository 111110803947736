import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fill-height":"","fluid":"","ma-0":"","pa-0":""}},[_c(VNavigationDrawer,{staticClass:"wiDrawer",attrs:{"app":"","temporary":"","height":"340px","color":_vm.colorByMode[_vm.$route.params.datamode || 0],"dark":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VRow,{staticClass:"pa-2 pl-4 ma-0",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Startseite")])],1)],1),_c(VRow,{staticClass:"pa-2 pt-8 pl-4 ma-0",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('span',{on:{"click":function($event){_vm.drawer = !_vm.drawer;
            _vm.$store.dispatch('glossarShow', '');}}},[_vm._v("Glossar")])])],1),_c(VRow,{staticClass:"pa-2 pl-4 ma-0",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('span',{on:{"click":function($event){_vm.drawer = !_vm.drawer;
            _vm.$store.dispatch('aboutShow', true);}}},[_vm._v("Über Waldinventur")])])],1),_c(VRow,{staticClass:"pa-2 pl-4 ma-0",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('a',{attrs:{"href":"https://www.bfw.gv.at","target":"_blank"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_vm._v("Über BFW")])])],1),_c(VRow,{staticClass:"pa-2 pt-8 pl-4 ma-0",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('span',{on:{"click":function($event){_vm.drawer = !_vm.drawer;
            _vm.$store.dispatch('contactShow', true);}}},[_vm._v("Kontakt")])])],1),_c(VRow,{staticClass:"pa-2 pl-4 ma-0",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('span',{on:{"click":function($event){_vm.drawer = !_vm.drawer;
            _vm.$store.dispatch('imprintShow', true);}}},[_vm._v("Impressum")])])],1)],1),_c(VRow,{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"fill-height",attrs:{"cols":_vm.mainCols}},[_c(VAppBar,{staticClass:"appBar",class:{ mobile: _vm.$store.state.isMobile },attrs:{"elevation":"0","height":"100","color":_vm.colorByMode[_vm.$route.params.datamode || 0]}},[_c(VBtn,{staticClass:"mb-10",attrs:{"title":"Hauptmenü","color":"#fff","icon":""},on:{"click":_vm.switchDrawer}},[_c(VImg,{staticClass:"ma-1",attrs:{"src":"BurgerMenue-white.svg","width":"40px"}})],1),_c('div',{staticClass:"whiteInBar"},[_c('img',{class:{ isHidden: _vm.$route.name !== 'Map' },attrs:{"src":"bfw-logo-color.svg","alt":"Logo des BFW","height":"46","width":"auto"}}),_c('div',{staticClass:"langLink"},[(_vm.$route.name !== 'Map')?_c(VBtn,{staticClass:"mr-2",attrs:{"elevation":"0","dark":"","outlined":_vm.$route.params.lang == 'en',"x-small":"","color":"rgb(68, 118, 129)"},on:{"click":_vm.langStartSwitch}},[_vm._v("DE")]):_vm._e(),(_vm.$route.name !== 'Map')?_c(VBtn,{staticClass:"mr-2",attrs:{"elevation":"0","dark":"","outlined":_vm.$route.params.lang != 'en',"x-small":"","color":"rgb(68, 118, 129)"},on:{"click":_vm.langStartSwitch}},[_vm._v("EN")]):_vm._e()],1)]),_c('TheMapHeader')],1),_c('router-view')],1),(_vm.$route.name === 'Map')?_c(VCol,{staticClass:"navigationArea",class:{ mobile: _vm.$store.state.isMobile },attrs:{"cols":_vm.naviCols}},[_c('TheAttributeInspector'),_c('TheTopicSelector',{ref:"topicselector"}),_c('wald-wissen')],1):_vm._e()],1),_c('TheBfiWarning')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }