import Vue from "vue";
import Vuex, { Store } from "vuex";
import { regionDataLayer, dataLayerGroup, setBaseLayer } from "../mixins/map";

Vue.use(Vuex);

export default new Store({
  state: {
    belowMinHeight: false,
    isMobile: false,
    mobileFilterOpen: false,
    attribute: null,
    attributeTab: null,
    tablesMissingBFIs: [],
    attributeName: "",
    attributeNameKurz: "",
    attributeDarstellung: "",
    verwaltungsebene: "Bundesland",
    periode: null,
    themenkartenname: "",
    selectedRegion: null,
    metadata: null,
    expertdata: null,
    glossar: null,
    glossarShow: null,
    aboutShow: false,
    imprintShow: false,
    contactShow: false,
    waldwissen: null,
    landingpagetexte: null,
    bfilookup: null,
    bfiwarning: 0,
    datenlookup: null,
    data: null,
    ergs: [],
    legendItems: [],
    opacity: 1,
    quantiles: false,
    rasterLayer: null,
    baseLayer: {
      below: "esri",
      above: "empty"
    }
  },
  mutations: {
    mobileFilterOpen(state, value) {
      state.mobileFilterOpen = value;
    },

    belowMinHeight(state, value) {
      state.belowMinHeight = value;
    },
    isMobile(state, value) {
      state.isMobile = value;
    },
    periode(state, value) {
      state.periode = value;
    },
    attribute(state, value) {
      state.attribute = value;
    },
    attributeTab(state, value) {
      state.attributeTab = value;
    },
    tablesMissingBFIs(state, value) {
      state.tablesMissingBFIs = value;
    },
    themenkartenname(state, value) {
      state.themenkartenname = value;
    },
    attributeDarstellung(state, value) {
      state.attributeDarstellung = value;
    },
    attributeName(state, value) {
      state.attributeName = value;
    },
    attributeNameKurz(state, value) {
      state.attributeNameKurz = value;
    },
    verwaltungsebene(state, value) {
      state.verwaltungsebene = value;
    },
    selectedRegion(state, value) {
      state.selectedRegion = value;
    },
    metadata(state, value) {
      state.metadata = value;
    },
    expertdata(state, value) {
      state.expertdata = value;
    },
    glossar(state, value) {
      state.glossar = value;
    },
    aboutShow(state, value) {
      state.aboutShow = value;
    },
    imprintShow(state, value) {
      state.imprintShow = value;
    },
    contactShow(state, value) {
      state.contactShow = value;
    },
    glossarShow(state, value) {
      state.glossarShow = value;
    },
    waldwissen(state, value) {
      state.waldwissen = value;
    },
    landingpagetexte(state, value) {
      state.landingpagetexte = value;
    },
    bfilookup(state, value) {
      state.bfilookup = value;
    },
    bfiwarning(state, value) {
      state.bfiwarning = value;
    },
    datenlookup(state, value) {
      state.datenlookup = value;
    },
    data(state, value) {
      state.data = value;
    },
    ergs(state, value) {
      state.ergs = value;
    },
    legendItems(state, value) {
      state.legendItems = value;
    },
    opacity(state, value) {
      state.opacity = value;
    },
    quantiles(state, value) {
      state.quantiles = value;
    },
    rasterLayer(state, value) {
      state.rasterLayer = value;
    },
    baseLayer(state, value) {
      state.baseLayer = value;
    }
  },
  actions: {
    mobileFilterOpen: ({ commit }, value) => {
      commit("mobileFilterOpen", value);
    },
    belowMinHeight: ({ commit }, value) => {
      commit("belowMinHeight", value);
    },
    isMobile: ({ commit }, value) => {
      commit("isMobile", value);
    },
    periode: ({ commit }, value) => {
      commit("periode", value);
    },
    themenkartenname: ({ commit }, value) => {
      commit("themenkartenname", value);
    },
    attribute: ({ commit }, value) => {
      commit("attribute", value);
    },
    attributeTab: ({ commit }, value) => {
      commit("attributeTab", value);
    },
    tablesMissingBFIs: ({ commit }, value) => {
      commit("tablesMissingBFIs", value);
    },
    attributeName: ({ commit }, value) => {
      commit("attributeName", value);
    },
    attributeNameKurz: ({ commit }, value) => {
      commit("attributeNameKurz", value);
    },
    attributeDarstellung: ({ commit }, value) => {
      commit("attributeDarstellung", value);
    },
    verwaltungsebene: ({ commit }, value) => {
      commit("verwaltungsebene", value);
    },
    selectedRegion: ({ commit }, value) => {
      commit("selectedRegion", value);
      regionDataLayer.changed();
    },
    metadata: ({ commit }, value) => {
      commit("metadata", value);
    },
    expertdata: ({ commit }, value) => {
      commit("expertdata", value);
    },
    glossarShow: ({ commit }, value) => {
      commit("glossarShow", value);
    },
    aboutShow: ({ commit }, value) => {
      commit("aboutShow", value);
    },
    imprintShow: ({ commit }, value) => {
      commit("imprintShow", value);
    },
    contactShow: ({ commit }, value) => {
      commit("contactShow", value);
    },
    glossar: ({ commit }, value) => {
      commit("glossar", value);
    },
    waldwissen: ({ commit }, value) => {
      commit("waldwissen", value);
    },
    landingpagetexte: ({ commit }, value) => {
      commit("landingpagetexte", value);
    },
    bfilookup: ({ commit }, value) => {
      commit("bfilookup", value);
    },
    bfiwarning: ({ commit }, value) => {
      commit("bfiwarning", value);
    },
    datenlookup: ({ commit }, value) => {
      commit("datenlookup", value);
    },
    data: ({ commit }, value) => {
      commit("data", value);
    },
    ergs: ({ commit }, value) => {
      commit("ergs", value);
    },
    legendItems: ({ commit }, value) => {
      commit("legendItems", value);
    },
    opacity: ({ commit }, value) => {
      commit("opacity", value);
      dataLayerGroup.set("opacity", value);
    },
    quantiles: ({ commit }, value) => {
      commit("quantiles", value);
    },
    rasterLayer: ({ commit }, value) => {
      commit("rasterLayer", value);
    },
    baseLayer: ({ commit }, value) => {
      commit("baseLayer", value);
      setBaseLayer(value.below, value.above);
    }
  },
  modules: {}
});
