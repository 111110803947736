<template>
  <v-overlay v-if="showMe">
    <v-alert
      width="calc(100vw - 20px)"
      max-width="500px"
      v-model="showMe"
      border="top"
      outlined
      color="rgb(68, 118, 129)"
      class="customizedWarning"
    >
      <h3>Hinweis:</h3>
      <p v-if="showWhat == 1">
        Die ausgewählte Bezirksforstinspektion (BFI) hat sich durch Zusammenlegungen bzw. Teilungen geändert. Daher
        liegt für diese Periode kein vergleichbares Ergebnis vor. Bitte treffen sie eine neue Auswahl.
      </p>
      <p v-if="showWhat == 2">
        Für diese Periode liegen keine Ergebnisse auf Gemeindeebene vor. Bitte treffen sie eine neue Auswahl.
      </p>
      <div align="center"><v-btn outlined color="rgb(68, 118, 129)" @click="showMe = false">Zurück</v-btn></div>
    </v-alert>
  </v-overlay>
</template>

<script>
export default {
  name: "TheBfiWarning",
  data: () => ({
    showMe: false,
    showWhat: 0
  }),
  created() {},
  mounted() {},
  updated() {},
  watch: {
    "$store.state.bfiwarning"(value) {
      if (value > 0) {
        this.showMe = true;
        this.showWhat = value;
        this.$store.dispatch("bfiwarning", 0);
      }
    }
  },
  computed: {
    tablePNG() {
      let ret = null;
      if (
        this.$route.params.datamode == "2" &&
        this.$store.state.attributeTab &&
        typeof this.$route.params.region != "undefined"
      ) {
        ret =
          "data/" +
          this.$route.params.erg +
          "/tabellen/" +
          this.$store.state.attributeTab.replace(/XXX/, this.$route.params.region.toString().padStart(3, "0")) +
          ".png";
      }
      return ret;
    },
    tablePDF() {
      let ret = null;
      if (
        this.$route.params.datamode == "2" &&
        this.$store.state.attributeTab &&
        typeof this.$route.params.region != "undefined"
      ) {
        ret =
          "data/" +
          this.$route.params.erg +
          "/tabellen/" +
          this.$store.state.attributeTab.replace(/XXX/, this.$route.params.region.toString().padStart(3, "0")) +
          ".pdf";
      }
      return ret;
    },
    tableXLS() {
      let ret = null;
      if (
        this.$route.params.datamode == "2" &&
        this.$store.state.attributeTab &&
        typeof this.$route.params.region != "undefined"
      ) {
        ret =
          "data/" +
          this.$route.params.erg +
          "/tabellen/" +
          this.$store.state.attributeTab.replace(/XXX/, this.$route.params.region.toString().padStart(3, "0")) +
          ".xlsx";
      }
      return ret;
    }
  },
  methods: {
    closeTableScreen() {
      this.$router.push({ params: { region: undefined } });
    }
  }
};
</script>

<style>
.customizedWarning {
  background-color: #fff !important;
}
</style>

<style scoped>
.tableScreen {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(69, 90, 78, 0.9);
  z-index: 5;
  padding: 0px;
  margin: 0px;
}

.tableScreen.mobile {
  position: fixed;
}

.imgViewer {
  position: absolute;
  left: 20px;
  top: 20px;
  border: solid 5px white;
  border-top: solid 40px white;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background-color: #fff;
  box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.3);
}

.tableScreen.mobile .imgViewer {
  left: 10px;
  top: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}

.imgViewer .innerViewer {
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 1px solid rgba(69, 90, 78, 0.5);
}

.imgViewer img {
  width: 100%;
  height: auto;
}

.imgNavi {
  position: absolute;
  width: 100%;
  left: 0px;
  top: -40px;
  height: 40px;
}

.imgNavi a {
  text-align: middle;
  font-size: 14px;
  text-decoration: none;
  color: rgb(69, 90, 78);
  text-transform: uppercase;
}

.imgNavi a img {
  width: 22px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
</style>
