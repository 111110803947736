<template>
  <div class="startPage">
    <TheGlossary />
    <TheAbout />
    <TheImprint />
    <TheContact />
    <v-img src="landingpage/grafik/forest_curved_low.jpg" width="100%" height="auto">
      <v-row no-gutters class="titleAlign">
        <v-col cols="0" sm="0" lg="1" />
        <v-col sm="12" lg="10" align="center">
          <v-img class="logoBottomMargin" height="calc(11vw)" contain src="bfw-logo-white.svg" />
          <h1 v-if="language == 'de'">ÖSTERREICHISCHE WALDINVENTUR</h1>
          <h3 v-if="language == 'de'">Waldinformationen aus erster Hand. Umfassend. Kompetent. Aktuell.</h3>
          <h1 v-if="language == 'en'">Austrian National Forest Inventory</h1>
          <h3 v-if="language == 'en'">Forest information at first hand. Comprehensive. Competent. Current.</h3>
        </v-col>
        <v-col cols="0" sm="0" lg="1" />
      </v-row>
    </v-img>
    <v-row no-gutters class="iconAlign">
      <v-col cols="0" sm="0" lg="1" />
      <v-col cols="12" sm="12" lg="10">
        <v-row no-gutters>
          <v-col cols="4" align="center"
            ><router-link to="/map/0"
              ><img
                alt="Regionale Auswertung"
                class="bigIcon"
                src="landingpage/icons/icon_regauswertung.svg" /></router-link
          ></v-col>
          <v-col cols="4" align="center"
            ><router-link to="/map/1"
              ><img
                alt="Themenkarten"
                class="bigIcon"
                height="auto"
                src="landingpage/icons/icon_themenkarten.svg" /></router-link
          ></v-col>
          <v-col cols="4" align="center"
            ><router-link to="/map/2"
              ><img
                alt="Tabellen"
                class="bigIcon"
                height="auto"
                src="landingpage/icons/icon_tabellen.svg" /></router-link
          ></v-col> </v-row
      ></v-col>
      <v-col cols="0" sm="0" lg="1" />
    </v-row>

    <v-row no-gutters class="pt-4 belowIcons">
      <v-col cols="0" sm="0" lg="1" />
      <v-col cols="12" sm="12" lg="10">
        <v-row no-gutters class="linkBlock">
          <v-col cols="4">
            <h2 class="reg" v-if="language == 'de'">Regionale Auswertungen</h2>
            <h2 class="reg" v-if="language == 'en'">Regional analyses</h2>
          </v-col>
          <v-col cols="4">
            <h2 class="them" v-if="language == 'de'">Themenkarten</h2>
            <h2 class="them" v-if="language == 'en'">Thematic maps</h2>
          </v-col>
          <v-col cols="4">
            <h2 class="tab" v-if="language == 'de'">Tabellen</h2>
            <h2 class="tab" v-if="language == 'en'">Tables</h2>
          </v-col>
        </v-row></v-col
      >
      <v-col cols="0" sm="0" lg="1" />
    </v-row>

    <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp">
      <v-col cols="0" sm="0" lg="1" />
      <v-col cols="12" sm="12" lg="10">
        <v-row no-gutters class="linkBlock">
          <v-col cols="4" class="px-6 pb-2"
            ><div
              v-html="
                $store.state.landingpagetexte ? $store.state.landingpagetexte['untericon_regausw'][language] : ''
              "
          /></v-col>
          <v-col cols="4" class="px-6 pb-2"
            ><div
              v-html="$store.state.landingpagetexte ? $store.state.landingpagetexte['untericon_themen'][language] : ''"
            />
          </v-col>
          <v-col cols="4" class="px-6 pb-2"
            ><div
              v-html="
                $store.state.landingpagetexte ? $store.state.landingpagetexte['untericon_tabellen'][language] : ''
              "
            />
          </v-col> </v-row
      ></v-col>
      <v-col cols="0" sm="0" lg="1" />
    </v-row>

    <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp">
      <v-col cols="0" sm="0" lg="1" />
      <v-col cols="12" sm="12" lg="10">
        <v-row no-gutters>
          <v-col cols="4">
            <router-link to="/map/0"
              ><div class="startButton reg">{{ language == "de" ? "Jetzt entdecken" : "Open" }}</div></router-link
            >
          </v-col>
          <v-col cols="4">
            <router-link to="/map/1"
              ><div class="startButton them">{{ language == "de" ? "Jetzt entdecken" : "Open" }}</div></router-link
            >
          </v-col>
          <v-col cols="4">
            <router-link to="/map/2"
              ><div class="startButton tab">{{ language == "de" ? "Jetzt entdecken" : "Open" }}</div></router-link
            >
          </v-col></v-row
        ></v-col
      >
      <v-col cols="0" sm="0" lg="1" />
    </v-row>

    <v-row no-gutters>
      <v-col cols="0" sm="0" lg="1" />
      <v-col cols="12" sm="12" lg="10">
        <v-row no-gutters>
          <v-col cols="12" class="pa-8 smallerText"
            ><div v-html="$store.state.landingpagetexte ? $store.state.landingpagetexte['untericons'][language] : ''"
          /></v-col> </v-row
      ></v-col>
      <v-col cols="0" sm="0" lg="1" />
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" md="6" v-if="$vuetify.breakpoint.mdAndUp">
        <v-img src="landingpage/grafik/image_auswertungen.jpg" />
      </v-col>
      <v-col cols="12" md="6" lg="5" class="pa-8 pt-0">
        <h2 class="reg" v-if="language == 'de'">Regionale Auswertungen</h2>
        <h2 class="reg" v-if="language == 'en'">Regional analyses</h2>
        <div v-html="$store.state.landingpagetexte ? $store.state.landingpagetexte['regausw'][language] : ''" />
        <router-link v-if="$vuetify.breakpoint.mdAndUp" to="/map/0"
          ><div class="startButton reg left">{{ language == "de" ? "Jetzt entdecken" : "Open" }}</div></router-link
        >
      </v-col>
      <v-col cols="12" md="6" v-if="!$vuetify.breakpoint.mdAndUp">
        <v-img src="landingpage/grafik/image_auswertungen.jpg" />
        <router-link to="/map/0"
          ><div class="startButton reg left">{{ language == "de" ? "Jetzt entdecken" : "Open" }}</div></router-link
        >
      </v-col>
      <v-col cols="0" sm="0" lg="1" />
    </v-row>

    <v-row no-gutters>
      <v-col cols="0" sm="0" lg="1" />
      <v-col cols="12" md="6" lg="5" class="pa-8 pt-0">
        <h2 class="them" v-if="language == 'de'">Themenkarten</h2>
        <h2 class="them" v-if="language == 'en'">Thematic maps</h2>
        <div v-html="$store.state.landingpagetexte ? $store.state.landingpagetexte['themen'][language] : ''" />
        <router-link v-if="$vuetify.breakpoint.mdAndUp" to="/map/1"
          ><div class="startButton them right">{{ language == "de" ? "Jetzt entdecken" : "Open" }}</div></router-link
        >
      </v-col>
      <v-col cols="12" md="6">
        <v-img src="landingpage/grafik/image_themenkarten.jpg" />
        <router-link v-if="!$vuetify.breakpoint.mdAndUp" to="/map/1"
          ><div class="startButton them right">{{ language == "de" ? "Jetzt entdecken" : "Open" }}</div></router-link
        >
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" md="6" v-if="$vuetify.breakpoint.mdAndUp">
        <v-img src="landingpage/grafik/image_tabellen.jpg" />
      </v-col>
      <v-col cols="12" md="6" lg="5" class="pa-8 pt-0">
        <h2 class="tab" v-if="language == 'de'">Tabellen</h2>
        <h2 class="tab" v-if="language == 'en'">Tables</h2>
        <div v-html="$store.state.landingpagetexte ? $store.state.landingpagetexte['tabellen'][language] : ''" />
        <router-link v-if="$vuetify.breakpoint.mdAndUp" to="/map/2"
          ><div class="startButton tab left">{{ language == "de" ? "Jetzt entdecken" : "Open" }}</div></router-link
        >
      </v-col>
      <v-col cols="0" sm="0" lg="1" />
      <v-col cols="12" md="6" v-if="!$vuetify.breakpoint.mdAndUp">
        <v-img src="landingpage/grafik/image_tabellen.jpg" />
        <router-link to="/map/2"
          ><div class="startButton tab left">{{ language == "de" ? "Jetzt entdecken" : "Open" }}</div></router-link
        >
      </v-col>
    </v-row>
    <Wissenswertes />

    <v-row no-gutters class="footer upper">
      <v-col cols="0" sm="0" lg="1" />
      <v-col cols="12" lg="3" sm="4" class="pa-8 pb-0">
        <span @click="$store.dispatch('glossarShow', '')">GLOSSAR</span>
        <span @click="$store.dispatch('aboutShow', true)">ÜBER WALDINVENTUR</span>
        <a href="https://www.bfw.gv.at" target="_blank">ÜBER BFW</a>
      </v-col>
      <v-col cols="12" lg="3" sm="4" class="pa-8 pb-0">
        <span v-if="$vuetify.breakpoint.smAndUp">&nbsp;</span>
        <span @click="$store.dispatch('contactShow', true)">KONTAKT</span>
        <span @click="$store.dispatch('imprintShow', true)">IMPRESSUM</span>
      </v-col>
      <v-col cols="6" sm="4" class="pa-8 pb-0" align="right" v-if="$vuetify.breakpoint.smAndUp">
        <img alt="Logo BFW" class="mt-2 footerLogo" src="bfw-logo-white.svg" />
      </v-col>
      <v-col cols="0" sm="0" lg="1" />
    </v-row>
    <v-row no-gutters class="footer lower">
      <v-col cols="0" sm="0" lg="1" />
      <v-col cols="6" lg="7" class="pa-8 pt-4" style="position: relative">
        <div class="smaller" :class="{ mobile: !$vuetify.breakpoint.smAndUp }">
          © 2022 BFW – Bundesforschungszentrum für Wald
        </div>
      </v-col>
      <v-col cols="6" lg="3" class="pa-8 pt-4" align="right">
        <img
          alt="Logo BFW"
          class="mt-2 footerLogo mobile"
          src="bfw-logo-white.svg"
          v-if="!$vuetify.breakpoint.smAndUp"
        />
        <a target="blank_" href="https://www.facebook.com/BundesforschungszentrumWald/"
          ><img alt="Facebook" src="landingpage/icons/icon_facebook.svg" width="auto" height="20"
        /></a>
        <a target="blank_" href="https://twitter.com/bfwald"
          ><img alt="Twitter" class="ml-3" src="landingpage/icons/icon_twitter.svg" width="auto" height="20"
        /></a>
        <a target="blank_" href="https://www.instagram.com/bundesforschungszentrum_wald/?hl=de"
          ><img alt="Instagramm" class="ml-3" src="landingpage/icons/icon_instagram.svg" width="auto" height="20"
        /></a>
        <a target="blank_" href="https://www.youtube.com/user/Waldforschung"
          ><img alt="Youtube" class="ml-3" src="landingpage/icons/icon_youtube.svg" width="auto" height="20"
        /></a>
      </v-col>
      <v-col cols="0" sm="0" lg="1" />
    </v-row>
  </div>
</template>

<script>
import Wissenswertes from "../components/Wissenswertes.vue";
import TheGlossary from "../components/TheGlossary.vue";
import TheAbout from "../components/TheAbout.vue";
import TheImprint from "../components/TheImprint.vue";
import TheContact from "../components/TheContact.vue";

export default {
  name: "TheStartPage",
  components: {
    Wissenswertes,
    TheGlossary,
    TheAbout,
    TheImprint,
    TheContact
  },
  data: () => ({}),
  created() {},
  mounted() {},
  updated() {},
  watch: {},
  computed: {
    language() {
      return this.$route.params.lang ? this.$route.params.lang : "de";
    }
  }
};
</script>

<style scoped>
.footerLogo {
  max-width: 150px;
  width: 80%;
  min-width: 100px;
  height: auto;
}
.footerLogo.mobile {
  margin-bottom: 10px;
  display: block;
}

.bigIcon {
  width: 10vw;
  height: auto;
}
.belowIcons {
  margin-top: 0px;
}
@media only screen and (max-width: 800px) {
  .bigIcon {
    width: 14vw;
  }
  .belowIcons {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 500px) {
  .bigIcon {
    width: 18vw;
  }
  .belowIcons {
    margin-top: 50px;
  }
}

.startPage {
  position: absolute;
  left: 0px;
  top: 100px;
  width: 100%;
  height: calc(100% - 100px);
  z-index: 1;
  overflow: auto;
  color: rgb(69, 89, 79);
  background-color: white;
}

.startPage a {
  text-decoration: none;
}

.smallerText {
  font-size: 15px;
}

.titleAlign {
  color: white;
  padding-top: 15px;
}
.titleAlign h1 {
  font-weight: 400;
  font-size: 1.2em;
}
.titleAlign h3 {
  margin-top: -10px;
  font-weight: 300;
  font-size: 0.6em;
}
@media only screen and (min-width: 600px) {
  .titleAlign h1 {
    font-weight: 400;
    font-size: 2em;
  }
  .titleAlign h3 {
    margin-top: -10px;
    font-weight: 300;
    font-size: 1em;
  }
}
@media only screen and (min-width: 800px) {
  .titleAlign h1 {
    font-weight: 400;
    font-size: 2.5em;
  }
  .titleAlign h3 {
    margin-top: -10px;
    font-weight: 300;
    font-size: 1.2em;
  }
}
@media only screen and (min-width: 1000px) {
  .titleAlign h1 {
    font-weight: 400;
    font-size: 3em;
  }
  .titleAlign h3 {
    margin-top: -10px;
    font-weight: 300;
    font-size: 1.4em;
  }
}
@media only screen and (min-width: 1300px) {
  .titleAlign h1 {
    font-weight: 400;
    font-size: 3.5em;
  }
  .titleAlign h3 {
    margin-top: -10px;
    font-weight: 300;
    font-size: 1.5em;
  }
}

.iconAlign {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 25vw;
}
@media only screen and (max-width: 800px) {
  .iconAlign {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 27vw;
  }
}

.iconAlign img {
  cursor: pointer;
}

.linkBlock h2 {
  text-align: center;
}
.startPage h2 {
  font-weight: 300;
  font-size: 2em;
  line-height: 1.2em;
  margin-bottom: 20px;
}
@media only screen and (max-width: 800px) {
  .startPage h2 {
    font-weight: 300;
    font-size: 1.5em;
    line-height: 1.1em;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .startPage h2 {
    font-weight: 300;
    font-size: 1em;
    line-height: 1em;
    margin-bottom: 20px;
  }
}

.them {
  color: #78a02c;
}

.reg {
  color: #447681;
}

.tab {
  color: #ac2a28;
}

.startButton {
  width: 180px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  color: #fff;
}
.startButton.left {
  margin-top: 30px;
  margin-left: 0;
  margin-right: auto;
}
.startButton.right {
  margin-top: 30px;
  margin-right: 0;
  margin-left: auto;
}

@media only screen and (max-width: 960px) {
  .startButton {
    width: 80vw;
    max-width: 300px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    color: #fff;
    margin-bottom: 50px;
  }
  .startButton.left {
    margin-left: auto;
    margin-right: auto;
  }
  .startButton.right {
    margin-right: auto;
    margin-left: auto;
  }
}

.startButton:hover {
  box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.45);
}

.startButton.reg {
  background-color: #447681;
}
.startButton.them {
  background-color: #78a02c;
}
.startButton.tab {
  background-color: #ac2a28;
}

.footer {
  background-color: #447681;
}

.footer span {
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
}

.footer.upper a,
.footer.upper span {
  cursor: pointer;
  font-weight: 300;
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 800px) {
  .footer.upper a,
  .footer.upper span {
    font-size: 15px;
  }
}

.footer .smaller {
  font-weight: 300;
  color: white;
  font-size: 13px;
}

.footer .smaller.mobile {
  position: absolute;
  font-weight: 300;
  color: white;
  font-size: 11px;
  bottom: 30px;
}

.logoBottomMargin {
  margin-bottom: 20px;
}
@media only screen and (max-width: 1200px) {
  .logoBottomMargin {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 900px) {
  .logoBottomMargin {
    margin-bottom: 0px;
  }
}
</style>
