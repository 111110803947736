<template>
  <div
    v-if="$store.state.aboutShow"
    class="aboutScreen"
    :class="{ onStart: typeof $route.params.datamode == 'undefined', mobile: $store.state.isMobile }"
  >
    <div class="aboutViewer">
      <v-row no-gutters class="pa-0 ma-0 aboutNavi">
        <v-col cols="10" class="pt-2"> </v-col>
        <v-col cols="2" class="pt-2" align="right">
          <v-icon title="Schließen" class="mr-2" @click="$store.state.aboutShow = false">mdi-close</v-icon>
        </v-col>
      </v-row>
      <div class="innerViewer pa-6" ref="innerViewer">
        <h1>Die Waldinventur</h1>
        <p>
          Die Österreichische Waldinventur (ÖWI) liefert seit mehr als einem halben Jahrhundert eine Vielzahl von Daten
          über den Zustand und die Veränderung des österreichischen Waldes. Sie ist damit die umfassendste
          Informationsbasis über das Ökosystem Wald und hilft bei der Darstellung ihrer ökologischen und ökonomisch
          nachhaltigen Nutzung. Ob als Entscheidungsgrundlage für die Wald- und Umweltpolitik oder als Basis für
          wissenschaftliche Projekte, die Ergebnisse der Waldinventur finden reichhaltige Anwendung.
        </p>
        <v-row no-gutters>
          <v-col md="6">
            <h2>ÖWI - Geländeerhebungen</h2>
            <p>
              Insgesamt umfasst das Stichprobennetz der ÖWI rund 11.000 Probeflächen im Wald. Für die Durchführung der
              umfangreichen Messungen auf den Probeflächen der ÖWI sind seit 2016 jährlich sieben Erhebungsteams
              bundesweit unterwegs. Rund 200 wald- und umweltrelevante Parameter werden erfasst. Jahrelange
              Außendiensterfahrung und eine sorgfältige Qualitätssicherung sorgen für einen hochwertigen und über die
              Jahre vergleichbaren Informationsgewinn. Mehr Informationen zu den Erhebungsparametern finden sie hier:
              <a href="./2016_Dienstanweisung_ÖWI_Fassung2020.pdf" target="_blank"
                >Dienstanweisung_ÖWI_Fassung2020.pdf</a
              >
            </p></v-col
          >
          <v-col md="6" class="pa-5 pt-0">
            <v-img src="landingpage/grafik/ueber_waldinventur_2.jpg" />
            <div class="imageCaption">Blick durch das Spiegelrelaskop zur Messung des oberen Stammdurchmessers.</div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col md="6">
            <h2>Waldinventur und Digitalisierung</h2>
            <p>
              Fernerkundungsmethoden sind bereits jetzt fixer Bestandteil der Ergebnispräsentation der ÖWI und diese
              werden laufend weiterentwickelt. Satellitendaten und Luftbilder aus der regelmäßigen, bundesweiten
              Befliegung eignen sich besonders für ein regelmäßiges Monitoring und die Kombination mit der ÖWI. Diese
              Daten ergänzen einander sehr gut. Luftbilder haben mit 0,2 m eine sehr hohe räumliche Auflösung, sind aber
              nur alle drei Jahre verfügbar. Satellitendaten haben oft eine viel höhere zeitliche Auflösung (15 - 30
              Aufnahmen pro Jahr), die einzelnen Pixel sind jedoch viel größer (z.B. 10 m). Für die ÖWI sind momentan
              vor allem die Daten des Sentinel 2-Satelliten interessant. Eine wichtige Basis für die Auswertungen dieser
              Daten bildet ein hochgenaues Geländemodell, das aus Laserscanningdaten berechnet wurde.
            </p>
            <p>
              Ab 2023 wird die ÖWI Jahresergebnisse anbieten, die nicht mehr ausschließlich auf den österreichweit
              Stichprobenraster beruhen. Dank der Kombination mit der Fernerkundung können künftig auch Auswertungen für
              kleinere Gebiete möglich sein, wie zum Beispiel für Gemeinden.
            </p>
          </v-col>
          <v-col md="6" class="pa-5 pt-0">
            <v-img src="landingpage/grafik/ueber_waldinventur_1.jpg" />
            <div class="imageCaption">
              Mit der 3D-Punktewolke sind alle Höhen der 3 Milliarden Bäume im Österreichischen Wald erfasst. Die
              Infrarotdarstellung hilft zusätzlich beim Auffinden von Totholz.
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheTableOverlay",
  data: () => ({}),
  created() {},
  mounted() {},
  updated() {},
  watch: {},
  computed: {},
  methods: {}
};
</script>

<style scoped>
.aboutScreen {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(69, 90, 78, 0.9);
  z-index: 5;
  padding: 0px;
  margin: 0px;
}

.aboutScreen.onStart,
.aboutScreen.mobile {
  position: fixed;
}

.aboutViewer {
  position: absolute;
  left: 20px;
  top: 20px;
  border-top: solid 40px white;
  width: calc(100% - 40px);
  height: calc(100% - 50px);
  background-color: #fff;
  box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.3);
}

.aboutScreen.onStart .aboutViewer {
  left: 40px;
  top: 120px;
  width: calc(100% - 80px);
  height: calc(100% - 140px);
}

.aboutScreen.mobile .aboutViewer {
  left: 10px;
  top: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}

.aboutViewer .innerViewer {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.aboutViewer .innerViewer h1 {
  font-weight: bold;
  color: #447681;
  font-size: 24px;
  margin-bottom: 20px;
}

.aboutViewer .innerViewer h2 {
  font-weight: 400;
  color: #6a7b71;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.aboutViewer .innerViewer p {
  font-size: 15px;
}
.aboutViewer .innerViewer a {
  text-decoration: none;
  color: #447681;
}

.aboutNavi {
  position: absolute;
  width: 100%;
  left: 0px;
  top: -40px;
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.imageCaption {
  padding: 4px;
  font-size: 13px;
  text-align: center;
}
</style>
