import { render, staticRenderFns } from "./TheSearch.vue?vue&type=template&id=5077a050&scoped=true&"
import script from "./TheSearch.vue?vue&type=script&lang=js&"
export * from "./TheSearch.vue?vue&type=script&lang=js&"
import style0 from "./TheSearch.vue?vue&type=style&index=0&id=5077a050&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5077a050",
  null
  
)

export default component.exports