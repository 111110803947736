<template>
  <div
    v-if="waldwissen.length > 0 && !$store.state.isMobile"
    class="pa-3 thewissenBox"
    :class="{ solo: waldwissen.length < 2, mobile: $store.state.isMobile, collapsed: collapsed }"
  >
    <v-icon @click="collapsed = false" v-if="$store.state.isMobile && collapsed" class="mobileCollapse"
      >mdi-information</v-icon
    >
    <v-carousel
      v-if="!collapsed"
      v-model="wwCounter"
      height="100"
      hide-delimiter-background
      :show-arrows="false"
      light
      :cycle="true"
      interval="7000"
      :continuous="true"
    >
      <v-carousel-item v-for="(element, index) in waldwissen" :key="index">
        <v-row no-gutters>
          <v-col cols="12" class="wissenHeader mb-1">
            <v-icon @click="collapsed = true" v-if="$store.state.isMobile && !collapsed" class="mobileCollapse"
              >mdi-arrow-collapse</v-icon
            >

            {{ element.header }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="3" class="pa-0 wissenImage"
            ><v-img width="80" height="76" :src="'data/' + element.bild" contain
          /></v-col>
          <v-col cols="9" class="pa-0 pl-2">
            <v-row no-gutters
              ><v-col cols="12" class="pa-1 pt-0 wissenContent">{{ element ? element.text : "" }}</v-col>
            </v-row>
            <v-row no-gutters
              ><v-col cols="12" class="pa-0 wissenLink"
                ><a
                  v-if="element.link != ''"
                  :href="element.link.substr(0, 4) != 'http' ? 'http://' + element.link : element.link"
                  target="_blank"
                  >... mehr erfahren</a
                ></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "wissenTicker",
  data: () => ({
    collapsed: false,
    wwCounter: 0
  }),
  created() {},
  mounted() {},
  updated() {},
  computed: {
    /** @returns {Array<{link: string, text: string, header: string, bild: string}>} */
    waldwissen() {
      let retVal = [];
      if (this.$store.state.waldwissen && this.$route.params.datamode == "0" && this.$store.state.attribute) {
        const meta = this.$store.state.metadata[this.$store.state.periode].find(
          (e) => e.Kodierung_ID == this.$store.state.attribute
        );
        if (meta) {
          const allesWissen = this.$store.state.waldwissen.filter(
            (e) =>
              e.bereich == "auswertung" &&
              e.Hierarchie_1 == meta.Hierarchie_1 &&
              (e.Hierarchie_2 == "" || e.Hierarchie_2 == meta.Hierarchie_2)
          );
          if (allesWissen.length) {
            retVal = allesWissen;
          }
        }
      }
      if (this.$store.state.waldwissen && this.$route.params.datamode == "2" && this.$store.state.attributeTab) {
        const meta = this.$store.state.expertdata[this.$store.state.periode].find(
          (e) => e.Kodierung_ID == this.$store.state.attributeTab
        );
        if (meta) {
          const allesWissen = this.$store.state.waldwissen.filter(
            (e) =>
              e.bereich == "tabellen" &&
              e.Hierarchie_1 == meta.Hierarchie_1 &&
              (e.Hierarchie_2 == "" || e.Hierarchie_2 == meta.Hierarchie_2)
          );
          if (allesWissen.length) {
            retVal = allesWissen;
          }
        }
      }
      return retVal;
    }
  }
};
</script>

<style scoped>
.thewissenBox button.v-icon.mobileCollapse {
  position: absolute;
  top: -2px;
  right: 0px;
  font-size: 22px !important;
}
.thewissenBox.collapsed button.v-icon.mobileCollapse {
  position: absolute;
  top: 1px;
  right: 1px;
  font-size: 22px !important;
  z-index: 100;
}

.thewissenBox {
  position: absolute;
  right: 0px;
  top: calc(100vh - 124px);
  top: calc(var(--vh, 1vh) * 100 - 124px);
}
.thewissenBox.mobile {
  right: auto;
  left: 15px;
  background-color: white;
  width: 260px;
  top: calc(100vh - 124px);
  top: calc(var(--vh, 1vh) * 100 - 230px);
  box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.3);
  z-index: 3;
}
.thewissenBox.mobile.collapsed {
  width: 24px;
  height: 24px;
  top: calc(var(--vh, 1vh) * 100 - 134px);
}

.wissenHeader {
  color: #455a4e;
  font-size: 14px;
  line-height: 20px;
  height: 20px;
}
.wissenImage {
  height: 80px;
  overflow: hidden;
}

.wissenContent {
  height: 60px;
  overflow: auto;
  font-size: 12px;
}

.wissenLink {
  height: 20px;
  font-size: 12px;
  overflow: hidden;
}
.wissenLink a {
  color: #447681;
  font-style: normal;
  text-decoration: none;
}
</style>

<style>
.thewissenBox div.v-carousel__controls {
  position: absolute;
  left: auto !important;
  bottom: auto !important;
  top: -15px !important;
  right: 0px !important;
  justify-content: end;
}
.thewissenBox div.v-carousel__controls .v-carousel__controls__item {
  margin: 0px !important;
}

.thewissenBox.solo div.v-carousel__controls {
  display: none;
}

.thewissenBox .theme--light.v-btn.v-btn--icon {
  color: #6a7b71;
  width: 19px;
  height: 19px;
}
.thewissenBox i.v-icon {
  font-size: 13px !important;
}
</style>
