<template>
  <v-container fill-height fluid ma-0 pa-0>
    <v-navigation-drawer
      class="wiDrawer"
      v-model="drawer"
      app
      temporary
      height="340px"
      :color="colorByMode[$route.params.datamode || 0]"
      dark
    >
      <v-row no-gutters class="pa-2 pl-4 ma-0"
        ><v-col cols="12"><router-link to="/">Startseite</router-link></v-col></v-row
      >
      <v-row no-gutters class="pa-2 pt-8 pl-4 ma-0"
        ><v-col cols="12"
          ><span
            @click="
              drawer = !drawer;
              $store.dispatch('glossarShow', '');
            "
            >Glossar</span
          ></v-col
        ></v-row
      >
      <v-row no-gutters class="pa-2 pl-4 ma-0"
        ><v-col cols="12"
          ><span
            @click="
              drawer = !drawer;
              $store.dispatch('aboutShow', true);
            "
            >Über Waldinventur</span
          ></v-col
        ></v-row
      >
      <v-row no-gutters class="pa-2 pl-4 ma-0"
        ><v-col cols="12"
          ><a @click="drawer = !drawer" href="https://www.bfw.gv.at" target="_blank">Über BFW</a></v-col
        ></v-row
      >
      <v-row no-gutters class="pa-2 pt-8 pl-4 ma-0"
        ><v-col cols="12"
          ><span
            @click="
              drawer = !drawer;
              $store.dispatch('contactShow', true);
            "
            >Kontakt</span
          ></v-col
        ></v-row
      >
      <v-row no-gutters class="pa-2 pl-4 ma-0"
        ><v-col cols="12"
          ><span
            @click="
              drawer = !drawer;
              $store.dispatch('imprintShow', true);
            "
            >Impressum</span
          ></v-col
        ></v-row
      >
    </v-navigation-drawer>
    <v-row no-gutters class="fill-height">
      <v-col :cols="mainCols" class="fill-height">
        <v-app-bar
          class="appBar"
          :class="{ mobile: $store.state.isMobile }"
          elevation="0"
          height="100"
          :color="colorByMode[$route.params.datamode || 0]"
        >
          <v-btn title="Hauptmenü" color="#fff" icon @click="switchDrawer" class="mb-10"
            ><v-img src="BurgerMenue-white.svg" width="40px" class="ma-1"></v-img
          ></v-btn>
          <div class="whiteInBar">
            <img
              :class="{ isHidden: $route.name !== 'Map' }"
              src="bfw-logo-color.svg"
              alt="Logo des BFW"
              height="46"
              width="auto"
            />
            <div class="langLink">
              <v-btn
                elevation="0"
                dark
                :outlined="$route.params.lang == 'en'"
                x-small
                color="rgb(68, 118, 129)"
                v-if="$route.name !== 'Map'"
                @click="langStartSwitch"
                class="mr-2"
                >DE</v-btn
              >
              <v-btn
                elevation="0"
                dark
                :outlined="$route.params.lang != 'en'"
                x-small
                color="rgb(68, 118, 129)"
                v-if="$route.name !== 'Map'"
                @click="langStartSwitch"
                class="mr-2"
                >EN</v-btn
              >
            </div>
          </div>
          <TheMapHeader />
        </v-app-bar>
        <router-view></router-view>
      </v-col>
      <v-col
        v-if="$route.name === 'Map'"
        :cols="naviCols"
        class="navigationArea"
        :class="{ mobile: $store.state.isMobile }"
      >
        <TheAttributeInspector />
        <TheTopicSelector ref="topicselector" />
        <wald-wissen />
      </v-col>
    </v-row>
    <TheBfiWarning />
  </v-container>
</template>

<script>
import markdownit from "markdown-it";
import axios from "redaxios";
import parseCsv from "csv-parse/lib/sync";
import TheMapHeader from "./TheMapHeader.vue";
import TheTopicSelector from "./TheTopicSelector.vue";
import TheAttributeInspector from "./TheAttributeInspector.vue";
import WaldWissen from "./WaldWissen.vue";
import TheBfiWarning from "@/components/TheBfiWarning.vue";

const md = markdownit({
  html: true,
  linkify: true,
  typographer: true
});

export default {
  data: () => ({ csvtexte: null, drawer: null, colorByMode: ["#447681", "#78a02c", "#ac2a28"] }),
  name: "Home",
  components: {
    TheBfiWarning,
    TheMapHeader,
    TheTopicSelector,
    TheAttributeInspector,
    WaldWissen
  },
  async created() {
    const responses = await Promise.all([axios("./data/landingpage/landingpage.txt")]);
    const texteObjekt = {};
    const [csvtexte] = responses.map((response) =>
      parseCsv(response.data, {
        delimiter: ";",
        columns: true,
        trim: true
      })
    );
    for (let i = 0; i < csvtexte.length; i++) {
      texteObjekt[csvtexte[i].key] = { de: md.render(csvtexte[i].de), en: md.render(csvtexte[i].en) };
    }
    this.$store.dispatch("landingpagetexte", texteObjekt);
  },
  computed: {
    mainCols() {
      let cols = 12;
      if (this.$route.name === "Map" && !this.$store.state.isMobile) {
        cols = 7;
        if (this.$vuetify.breakpoint.lgAndUp) {
          cols = 9;
        }
        if (this.$vuetify.breakpoint.mdAndUp) {
          cols = 8;
        }
      }
      return cols;
    },
    naviCols() {
      let cols = 12;
      if (this.$route.name === "Map" && !this.$store.state.isMobile) {
        cols = 5;
        if (this.$vuetify.breakpoint.lgAndUp) {
          cols = 3;
        }
        if (this.$vuetify.breakpoint.mdAndUp) {
          cols = 4;
        }
      }
      return cols;
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    switchDrawer() {
      this.drawer = !this.drawer;
      if (this.drawer) {
        this.$store.dispatch("glossarShow", null);
        this.$store.dispatch("aboutShow", false);
        this.$store.dispatch("contactShow", false);
        this.$store.dispatch("imprintShow", false);
      }
    },
    onResize() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      this.$store.dispatch("isMobile", window.innerWidth < 800 || window.innerHeight < 400 ? true : false);
      this.$store.dispatch("belowMinHeight", window.innerHeight < 700 ? true : false);
    },
    langStartSwitch() {
      this.$store.dispatch("glossarShow", null);
      this.$store.dispatch("aboutShow", false);
      this.$store.dispatch("contactShow", false);
      this.$store.dispatch("imprintShow", false);
      this.$router.push(!this.$route.params.lang || this.$route.params.lang == "de" ? "/en" : "/de");
    }
  },
  watch: {}
};
</script>

<style>
.appBar {
  overflow: hidden;
  z-index: 2;
}
.appBar.mobile {
  z-index: 0;
}

.wiDrawer.v-navigation-drawer.v-navigation-drawer--fixed {
  top: 100px !important;
  z-index: 10;
}

.wiDrawer .v-navigation-drawer__content .row .col a,
.wiDrawer .v-navigation-drawer__content .row .col span {
  cursor: pointer;
  font-weight: 300;
  font-size: 18px;
  color: white;
}
.wiDrawer .v-navigation-drawer__content .row .col a:hover,
.wiDrawer .v-navigation-drawer__content .row .col span:hover {
  color: #a0bc6b;
}

.navigationArea {
  height: 100vh;
  position: relative;
  background-color: white;
  box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.navigationArea.mobile {
  height: auto;
  position: absolute;
  left: 0px;
  top: 100px;
}

.v-navigation-drawer__content .row.drawerHead {
  height: 100px;
  background-color: rgb(0, 102, 67);
  color: #fff;
}

.v-navigation-drawer__content .row .col a,
.v-navigation-drawer__content .row .col span {
  text-decoration: none;
  color: #333;
}

.whiteInBar {
  position: absolute;
  top: 0px;
  right: 0px;
  width: calc(100% - 60px);
  background-color: #fff;
  box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.3);
  padding: 0px;
  padding-top: 4px;
  padding-left: 14px;
}

.langLink {
  cursor: pointer;
  font-size: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.isHidden {
  visibility: hidden;
}
</style>
