import Vue from "vue";
import VueRouter from "vue-router";
import StartPage from "../views/StartPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:lang?",
    name: "StartPage",
    component: StartPage
  },
  {
    path: "/map/:datamode/:attribute/:verwaltungsebene/:erg?/:region?",
    name: "Map",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "map" */ "../views/Map.vue")
  },
  {
    path: "/map",
    redirect: "/map/0/-/Bundesland"
  },
  {
    path: "/map/:datamode",
    redirect: "/map/:datamode/-/Bundesland"
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

export default router;
