<template>
  <div
    v-if="
      Number($route.params.datamode) == 0 &&
      $store.state.selectedRegion &&
      typeof $store.state.selectedRegion.lbfi != 'undefined'
    "
    class="attributeInspector"
    :class="{ mobile: $store.state.isMobile }"
  >
    <v-card flat tile>
      <v-row no-gutters class="smallUpper"
        ><v-col cols="10"
          ><span>{{ $store.state.selectedRegion.name }}</span></v-col
        ><v-col cols="2" align="right"
          ><v-btn @click="closePanel" class="ma-0" icon small
            ><v-icon color="#455a4e" class="ma-0">mdi-close</v-icon></v-btn
          ></v-col
        ></v-row
      >
      <v-row v-if="!prozentwertfehler.datenfehler" no-gutters
        ><v-col cols="12" class="smallLower">
          <div v-if="prozentwertfehler.wert">
            <strong>{{ prozentwertfehler.wert }} {{ prozentwertfehler.einheit }}</strong
            >&nbsp;<span v-if="prozentwertfehler.fehler">({{ prozentwertfehler.fehler }})</span>
          </div>
          <div v-if="prozentwertfehler.prozent">
            <strong>{{ prozentwertfehler.prozent }}%</strong>&nbsp;bezogen auf {{ bezugsGroesse }}
          </div>
        </v-col></v-row
      >
      <v-row v-if="prozentwertfehler.datenfehler" no-gutters
        ><v-col cols="12" class="smallLower">{{ prozentwertfehler.datenfehler }}</v-col></v-row
      >
    </v-card>
  </div>
</template>

<script>
import { regionDataLayer, map, dataLayerGroup } from "../mixins/map";

export default {
  name: "TheAttributeInspector",
  data: function () {
    return {};
  },
  watch: {
    "$route.params.region"(value) {
      this.showFeature(value);
    },
    "$store.state.data"() {
      if (this.$route.params.region) {
        this.showFeature(this.$route.params.region);
      }
    }
  },
  computed: {
    prozentwertfehler() {
      let result = {
        datenfehler: null,
        prozent: null,
        wert: null,
        einheit: null,
        fehler: null
      };
      const numberFormat = new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1
      });
      const metadata = this.$store.state.metadata[this.$store.state.periode];
      if (
        metadata &&
        this.$store.state.attribute &&
        this.$store.state.selectedRegion &&
        typeof this.$store.state.selectedRegion.lbfi != "undefined"
      ) {
        const data = this.$store.state.metadata[this.$store.state.periode].find(
          (record) => record["Kodierung_ID"] === this.$store.state.attribute
        );
        result = {
          datenfehler:
            this.$store.state.selectedRegion[`${this.$store.state.attribute}_Proz`] == "KD"
              ? this.$store.state.datenlookup.find((e) => e.Fehlerwert == "KD").Bedeutung
              : null,
          einheit: data.Einheit,
          prozent:
            typeof this.$store.state.selectedRegion[`${this.$store.state.attribute}_Proz`] != "undefined"
              ? numberFormat.format(this.$store.state.selectedRegion[`${this.$store.state.attribute}_Proz`])
              : null,
          wert:
            typeof this.$store.state.selectedRegion[`${this.$store.state.attribute}_Wert`] != "undefined"
              ? numberFormat.format(
                  Number(this.$store.state.selectedRegion[`${this.$store.state.attribute}_Wert`]) *
                    Number(data.Multiplikator)
                )
              : null,
          fehler:
            typeof this.$store.state.selectedRegion[`${this.$store.state.attribute}_Fehler`] != "undefined"
              ? isNaN(Number(this.$store.state.selectedRegion[`${this.$store.state.attribute}_Fehler`]))
                ? this.$store.state.datenlookup.find(
                    (e) => e.Fehlerwert == this.$store.state.selectedRegion[`${this.$store.state.attribute}_Fehler`]
                  ).Bedeutung
                : "± " +
                  numberFormat.format(
                    Number(this.$store.state.selectedRegion[`${this.$store.state.attribute}_Fehler`]) *
                      Number(data.Multiplikator)
                  ) +
                  " " +
                  data.Einheit
              : null
        };
      }
      return result;
    },
    metadata() {
      return this.$store.state.metadata[this.$store.state.periode].find(
        (record) => record["Kodierung_ID"] === this.$store.state.attribute
      );
    },
    bezugsGroesse() {
      if (!this.$store.state.attribute || this.$store.state.attributeDarstellung == "Wert") {
        return null;
      }
      const meta = this.$store.state.metadata[this.$store.state.periode].find(
        (record) => record["Kodierung_ID"] === this.$store.state.attribute
      );
      if (meta.Bezugsgröße_für_Prozent.length > 0) {
        const bezug = meta.Bezugsgröße_für_Prozent;
        const bezugsMeta = this.$store.state.metadata[this.$store.state.periode].find(
          (record) => record["Kodierung_ID"] === bezug
        );
        return bezugsMeta.Bezeichnung;
      } else {
        return "";
      }
    }
  },
  mounted() {
    map.on("click", this.select);
  },
  beforeDestroy() {
    map.un("click", this.select);
  },
  methods: {
    showFeature(lbfi) {
      if (this.$route.params.datamode == "0" && lbfi != undefined) {
        map.once("rendercomplete", () => {
          const features = regionDataLayer
            .getSource()
            .getFeaturesInExtent(map.getView().calculateExtent(map.getSize()));
          const thisErg = this.$route.params.erg;
          const thisLayer = `${thisErg}_daten_bfi_outline`;
          const feature = features.find((f) => f.get("lbfi") == lbfi && f.get("layer") === thisLayer);
          this.$store.dispatch(
            "selectedRegion",
            feature && features.length
              ? Object.assign(feature.getProperties(), this.$store.state.data[feature.get("lbfi")])
              : null
          );
        });
        map.render();
      }
    },
    closePanel() {
      this.$store.dispatch("selectedRegion", null);
      this.$router.push({ params: { region: undefined } });
    },
    /**
     * @param {import("ol/MapBrowserEvent").default} e
     */
    select(e) {
      // either the selected feature, or the pixel value, if clicked on raster map
      let selectedItem;

      if (Number(this.$route.params.datamode) == 1) {
        const color = dataLayerGroup.getLayersArray()[0].getData(e.pixel);
        // @ts-ignore
        selectedItem = color.slice(0, 3);
      } else {
        const features = map.getFeaturesAtPixel(e.pixel);
        if (features.length && typeof features[0].getId() == "undefined") {
          return;
        }
        if (features && features.length == 0) {
          return;
        }
        selectedItem = features[0] || null;
      }

      switch (Number(this.$route.params.datamode)) {
        // datamodes:
        // 0 == "Daten" ("regionale Auswertung")
        // 1 == "Raster" ("Themenkarten")
        // 2 == "Tabellen"
        case 0:
          this.$store.dispatch(
            "selectedRegion",
            selectedItem
              ? Object.assign(selectedItem.getProperties(), this.$store.state.data[selectedItem.get("lbfi")])
              : null
          );
          this.$router.push({ params: { region: selectedItem ? selectedItem.get("lbfi") : undefined } });
          break;
        case 1:
          this.$store.dispatch("selectedRegion", selectedItem ? selectedItem : null);
          break;
        case 2:
          if (
            typeof selectedItem != "undefined" &&
            this.$store.state.tablesMissingBFIs.indexOf(selectedItem.get("lbfi").toString()) == -1
          ) {
            this.$router.push({ params: { region: selectedItem ? selectedItem.get("lbfi") : undefined } });
          }
          break;
      }
    }
  }
};
</script>

<style scoped>
.attributeInspector {
  width: 260px;
  max-width: 260px;
  overflow: hidden;
  position: absolute;
  top: 115px;
  left: -280px;
  box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.attributeInspector.mobile {
  left: 15px;
  top: calc(100vh - 200px);
  top: calc(var(--vh, 1vh) * 100 - 200px);
}

.smallUpper {
  border-bottom: 1px solid #455a4e;
  height: auto;
  min-height: 30px;
  padding-left: 10px;
}

.smallUpper .col span {
  display: inline-block;
  color: #455a4e;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
  margin-top: 5px;

  text-transform: uppercase;
}

.smallLower.col {
  color: #455a4e;
  font-size: 13px;
  padding: 10px;
}
</style>
