<template>
  <div
    v-if="glossaryItem !== null"
    class="glossaryScreen"
    :class="{ onStart: typeof $route.params.datamode == 'undefined', mobile: $store.state.isMobile }"
  >
    <div class="glossaryViewer">
      <v-row no-gutters class="pa-0 ma-0 glossaryNavi">
        <v-col cols="10" class="pt-2"> </v-col>
        <v-col cols="2" class="pt-2" align="right">
          <v-icon title="Schließen" class="mr-2" @click="closeGlossary">mdi-close</v-icon>
        </v-col>
      </v-row>
      <div class="innerViewer pa-3" ref="innerViewer">
        <h1 class="pl-3">Glossar</h1>
        <v-row
          v-for="(item, index) in glossar"
          :key="index"
          class="pa-3 ma-3 einBegriff"
          :class="{ highlight: $store.state.glossarShow == item.Begriff }"
          :ref="item.Begriff"
        >
          <v-col cols="12" class="pa-0"
            ><h2>{{ item.Begriff }}</h2></v-col
          >
          <v-col :lg="item.Bild != '' ? 6 : 12" sm="12" class="pa-0">
            <p>{{ item.Erläuterung.replace(/"/g, "") }}</p>
            <div v-if="item.Infolink != ''" class="infoLink mt-2 pt-2">
              <a :href="item.Infolink" target="_blank"
                ><v-icon title="Link folgen" color="#6a7b71" class="mr-2 mb-1">mdi-information</v-icon>Mehr
                erfahren...</a
              >
            </div>
          </v-col>
          <v-col sm="12" lg="6" v-if="item.Bild != ''" class="pl-6">
            <v-img contain width="100%" height="auto" :src="'data/' + item.Bild"
          /></v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TheTableOverlay",
  data: () => ({}),
  created() {},
  mounted() {},
  updated() {},
  watch: {},
  computed: {
    ...mapState({
      /** @returns {Array<{Begriff: string, Erläuterung: string, Infolink: string}>} */
      glossar: (state) => state.glossar
    }),
    glossaryItem() {
      let ret = null;
      const begriff = this.$store.state.glossarShow;
      if (typeof begriff === "string") {
        ret = true;
        if (begriff.length) {
          this.$nextTick(() => {
            //@ts-ignore
            this.$vuetify.goTo(this.$refs[begriff][0], {
              container: /** @type {HTMLElement} */ (this.$refs.innerViewer)
            });
          });
        }
      }
      return ret;
    }
  },
  methods: {
    closeGlossary() {
      this.$store.dispatch("glossarShow", null);
    }
  }
};
</script>

<style scoped>
.glossaryScreen {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(69, 90, 78, 0.9);
  z-index: 5;
  padding: 0px;
  margin: 0px;
}

.glossaryScreen.onStart,
.glossaryScreen.mobile {
  position: fixed;
}

.glossaryViewer {
  position: absolute;
  left: 20px;
  top: 20px;
  border-top: solid 40px white;
  width: calc(100% - 40px);
  height: calc(100% - 50px);
  background-color: #fff;
  box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.3);
}

.glossaryScreen.onStart .glossaryViewer {
  left: 40px;
  top: 120px;
  width: calc(100% - 80px);
  height: calc(100% - 140px);
}

.glossaryScreen.mobile .glossaryViewer {
  left: 10px;
  top: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}

.glossaryViewer .innerViewer {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.glossaryViewer .innerViewer h1 {
  font-weight: bold;
  color: #447681;
  font-size: 24px;
}

.glossaryViewer .innerViewer h2 {
  font-weight: 400;
  color: #6a7b71;
  font-size: 18px;
  margin-bottom: 10px;
}

.glossaryViewer .innerViewer .einBegriff {
  border: 1px solid #6a7b71;
  font-size: 13px;
  white-space: pre-wrap;
}

.glossaryViewer .innerViewer .einBegriff.highlight {
  background-color: #fafafa;
}

.glossaryNavi {
  position: absolute;
  width: 100%;
  left: 0px;
  top: -40px;
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.glossaryViewer .innerViewer .infoLink {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.glossaryNavi a,
.infoLink a {
  text-align: middle;
  font-size: 13px;
  text-decoration: none;
  color: rgb(69, 90, 78);
}
</style>
