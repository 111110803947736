<template>
  <div
    v-if="$store.state.contactShow"
    class="contactScreen"
    :class="{ onStart: typeof $route.params.datamode == 'undefined', mobile: $store.state.isMobile }"
  >
    <div class="contactViewer">
      <v-row no-gutters class="pa-0 ma-0 contactNavi">
        <v-col cols="10" class="pt-2"> </v-col>
        <v-col cols="2" class="pt-2" align="right">
          <v-icon title="Schließen" class="mr-2" @click="$store.state.contactShow = false">mdi-close</v-icon>
        </v-col>
      </v-row>
      <div class="innerViewer pa-6" ref="innerViewer">
        <h1>Kontakt</h1>
        <h2>Leitung der Österreichischen Waldinventur:</h2>
        <p>
          Klemens Schadauer (<a href="mailto:klemens.schadauer@bfw.gv.at">klemens.schadauer@bfw.gv.at</a>)<br />
          Alexandra Freudenschuß (<a href="alexandra.freudenschuss@bfw.gv.at">alexandra.freudenschuss@bfw.gv.at</a>)
        </p>
        <h2>Technische Umsetzung und Kartographie:</h2>
        <p>
          Thomas Öberseder<br />
          Christoph Bauerhansl (<a href="mailto:christoph.bauerhansl@bfw.gv.at">christoph.bauerhansl@bfw.gv.at</a
          >)<br />
          Gernot Felfernig (<a href="mailto:gernot.felfernig@bfw.gv.at">gernot.felfernig@bfw.gv.at</a>)
        </p>
        <h2>Systembetreuung:</h2>
        <p>Heimo Schaffer (<a href="mailto:heimo.schaffer@bfw.gv.at">heimo.schaffer@bfw.gv.at</a>)</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheTableOverlay",
  data: () => ({}),
  created() {},
  mounted() {},
  updated() {},
  watch: {},
  computed: {},
  methods: {}
};
</script>

<style scoped>
.contactScreen {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(69, 90, 78, 0.9);
  z-index: 5;
  padding: 0px;
  margin: 0px;
}

.contactScreen.onStart,
.contactScreen.mobile {
  position: fixed;
}

.contactViewer {
  position: absolute;
  left: 20px;
  top: 20px;
  border-top: solid 40px white;
  width: calc(100% - 40px);
  height: calc(100% - 50px);
  background-color: #fff;
  box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.3);
}

.contactScreen.onStart .contactViewer {
  left: 40px;
  top: 120px;
  width: calc(100% - 80px);
  height: calc(100% - 140px);
}

.contactScreen.mobile .contactViewer {
  left: 10px;
  top: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}

.contactViewer .innerViewer {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.contactViewer .innerViewer h1 {
  font-weight: bold;
  color: #447681;
  font-size: 24px;
  margin-bottom: 20px;
}

.contactViewer .innerViewer h2 {
  font-weight: 400;
  color: #6a7b71;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.contactViewer .innerViewer p {
  font-size: 15px;
}
.contactViewer .innerViewer a {
  text-decoration: none;
  color: #447681;
}

.contactNavi {
  position: absolute;
  width: 100%;
  left: 0px;
  top: -40px;
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
</style>
