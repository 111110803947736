<template>
  <div>
    <div
      v-if="glossaryItem || $route.name == 'StartPage'"
      class="glossaryShown"
      :class="{ startPage: $route.name == 'StartPage' }"
    >
      <div class="greenBack">
        <div class="whiteInBar"></div>
        <div class="slogan"><v-icon class="mr-1 mb-1" small color="white">mdi-home</v-icon>WALDINVENTUR</div>
      </div>
    </div>
    <v-overlay v-if="isLoading" color="#447681" opacity=".5" z-index="10000"
      ><v-progress-circular color="#fff" rotate indeterminate size="80" width="6"></v-progress-circular
    ></v-overlay>
    <div>
      <div class="shadowDropper" :class="{ mobile: $store.state.isMobile }">
        <v-row no-gutters class="pa-0">
          <v-col cols="12" class="searchContainer" align="center"
            ><TheSearch
              v-if="erg && $store.state.metadata[erg] && $store.state.expertdata[erg] && $store.state.glossar"
          /></v-col>
        </v-row>
      </div>
      <v-tabs
        background-color="white"
        class="ma-0 pa-0"
        v-model="dataMode"
        hide-slider
        fixed-tabs
        :class="{ 'pt-1': $store.state.isMobile }"
      >
        <v-tab key="Daten" class="pa-0 ma-0"
          ><div class="tabContent tab1" :class="(tabSelCol, { mobile: $store.state.isMobile })">
            Regionale<br />Auswertung
          </div></v-tab
        >
        <v-tab key="Raster" class="pa-0 ma-0"
          ><div class="tabContent tab2" :class="tabSelCol">Themen-<br />Karten</div></v-tab
        >
        <v-tab key="Tabellen" class="pa-0 ma-0" :disabled="tabLocked"
          ><div class="tabContent tab3" :class="tabSelCol">Tabellen</div></v-tab
        >
      </v-tabs>
    </div>

    <v-tabs-items
      v-model="dataMode"
      class="tabDiv"
      :class="{ closed: $store.state.isMobile && !$store.state.mobileFilterOpen }"
    >
      <v-row no-gutters class="mobileFilterSwitch" v-show="$store.state.isMobile" @click="switchMobileFilter">
        <v-col cols="11" align="center"> <v-img src="icon_filter.svg" width="20" class="mr-4" />FILTER </v-col>
        <v-col cols="1">
          <v-icon v-if="!$store.state.mobileFilterOpen" large color="#455a4e">mdi-chevron-down</v-icon>
          <v-icon v-if="$store.state.mobileFilterOpen" large color="#455a4e">mdi-chevron-up</v-icon>
        </v-col>
      </v-row>
      <v-tab-item key="Daten" class="px-0" :class="{ 'py-2': !$store.state.isMobile }">
        <v-expansion-panels
          v-if="Number($route.params.datamode) == 0 && erg && $store.state.metadata[erg]"
          v-model="panelIndex"
          flat
          class="pa-0 ma-0"
          :class="{
            'mt-2': !$store.state.isMobile,
            belowMinHeight: $store.state.belowMinHeight,
            isMobile: $store.state.isMobile
          }"
        >
          <v-expansion-panel class="pa-0 ma-0">
            <v-expansion-panel-header class="selectHead">
              <div class="label">PERIODE</div>
              <span>{{ ergListe.find((el) => el.value == erg).text }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="pa-0 pb-2 ma-0 selectContent"
              :style="'min-height:' + Math.min(5, ergListe.length) * 30 + 'px'"
            >
              <v-row
                class="selectRow pa-0 ma-0"
                no-gutters
                v-for="(item, index) in this.ergListe"
                :key="index"
                @click="setPanelItem('erg', item.value)"
              >
                <v-col cols="12">
                  <v-icon v-if="item.value != erg" small class="ml-2 mr-2">mdi-radiobox-blank</v-icon>
                  <v-icon v-if="item.value == erg" small class="ml-2 mr-2">mdi-radiobox-marked</v-icon>
                  <span>{{ item.text }}</span></v-col
                >
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="pa-0 ma-0" @change="scrollInit('daten', 1)">
            <v-expansion-panel-header class="selectHead">
              <div class="label">THEMA</div>
              <span>{{ hierarchie1["Daten"] }}{{ hierarchie1Einheit }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="pa-0 pr-2 ma-0 selectContent"
              id="h1daten"
              @scroll.native="handleScroll"
              :style="'min-height:' + Math.min(5, this.hierarchien('Daten', 1).length) * 30 + 'px'"
            >
              <div class="fullContent">
                <div class="scrollindicator top"><v-icon large color="rgba(0,0,0,.2)">mdi-menu-up</v-icon></div>
                <div class="scrollindicator bottom">
                  <v-icon large color="rgba(0,0,0,.2)">mdi-menu-down</v-icon>
                </div>
                <v-row
                  class="selectRow pa-0 ma-0"
                  no-gutters
                  v-for="(item, index) in this.hierarchien('Daten', 1)"
                  :key="index"
                  @click="setHierarchy('Daten', 1, item.value, item.darstellung, item.einheit)"
                >
                  <v-col cols="8">
                    <v-icon
                      v-if="item.value + item.einheit != hierarchie1['Daten'] + hierarchie1Einheit"
                      small
                      class="ml-2 mr-2"
                      >mdi-radiobox-blank</v-icon
                    >
                    <v-icon
                      v-if="item.value + item.einheit == hierarchie1['Daten'] + hierarchie1Einheit"
                      small
                      class="ml-2 mr-2"
                      >mdi-radiobox-marked</v-icon
                    >
                    <span>{{ item.value }}{{ item.einheit }}</span></v-col
                  >
                  <v-col cols="4" align="right">
                    <div class="regionMarker" :class="{ active: item.bl == 1 }">BL</div>
                    <div class="regionMarker" :class="{ active: item.bfi == 1 }">BFI</div>
                    <div class="regionMarker" :class="{ active: item.gem == 1 }">G</div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="ma-3">
                  <v-col cols="4"
                    ><div class="regionMarker active">BL</div>
                    <div class="markerLegend">Bundesland</div></v-col
                  >
                  <v-col cols="4"
                    ><div class="regionMarker active">BFI</div>
                    <div class="markerLegend">Bezirksforst-<br />inspektion</div></v-col
                  >
                  <v-col cols="4"
                    ><div class="regionMarker active">G</div>
                    <div class="markerLegend">Gemeinde</div></v-col
                  >
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="pa-0 ma-0" @change="scrollInit('daten', 2)">
            <v-expansion-panel-header class="selectHead">
              <div class="label">KATEGORIE</div>
              <span>{{ hierarchie2["Daten"] }}{{ hierarchie2Einheit }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="pa-0 pr-2 ma-0 selectContent"
              id="h2daten"
              @scroll.native="handleScroll"
              :style="'min-height:' + Math.min(5, this.hierarchien('Daten', 2).length) * 30 + 'px'"
            >
              <div class="fullContent">
                <div class="scrollindicator top"><v-icon large color="rgba(0,0,0,.2)">mdi-menu-up</v-icon></div>
                <div class="scrollindicator bottom">
                  <v-icon large color="rgba(0,0,0,.2)">mdi-menu-down</v-icon>
                </div>
                <v-row
                  class="selectRow pa-0 ma-0"
                  no-gutters
                  v-for="(item, index) in this.hierarchien('Daten', 2)"
                  :key="index"
                  @click="setHierarchy('Daten', 2, item.value, item.darstellung, item.einheit)"
                >
                  <v-col cols="8">
                    <v-icon
                      v-if="item.value + item.einheit != hierarchie2['Daten'] + hierarchie2Einheit"
                      small
                      class="ml-2 mr-2"
                      >mdi-radiobox-blank</v-icon
                    >
                    <v-icon
                      v-if="item.value + item.einheit == hierarchie2['Daten'] + hierarchie2Einheit"
                      small
                      class="ml-2 mr-2"
                      >mdi-radiobox-marked</v-icon
                    >
                    <span>{{ item.value }}{{ item.einheit }}</span></v-col
                  >
                  <v-col cols="4" align="right">
                    <div class="regionMarker" :class="{ active: item.bl == 1 }">BL</div>
                    <div class="regionMarker" :class="{ active: item.bfi == 1 }">BFI</div>
                    <div class="regionMarker" :class="{ active: item.gem == 1 }">G</div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="ma-3 pb-5">
                  <v-col cols="4"
                    ><div class="regionMarker active">BL</div>
                    <div class="markerLegend">Bundesland</div></v-col
                  >
                  <v-col cols="4"
                    ><div class="regionMarker active">BFI</div>
                    <div class="markerLegend">Bezirksforst-<br />inspektion</div></v-col
                  >
                  <v-col cols="4"
                    ><div class="regionMarker active">G</div>
                    <div class="markerLegend">Gemeinde</div></v-col
                  >
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            :class="{ hiddenPanel: !isHierarchie3 }"
            class="pa-0 ma-0"
            @change="scrollInit('daten', 3)"
          >
            <v-expansion-panel-header class="selectHead">
              <div class="label">UNTERKATEGORIE</div>
              <span>{{ hierarchie3["Daten"] }}{{ hierarchie3Einheit }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="pa-0 pr-2 ma-0 selectContent"
              id="h3daten"
              @scroll.native="handleScroll"
              :style="'min-height:' + Math.min(5, this.hierarchien('Daten', 3).length) * 30 + 'px'"
            >
              <div class="fullContent">
                <div class="scrollindicator top"><v-icon large color="rgba(0,0,0,.2)">mdi-menu-up</v-icon></div>
                <div class="scrollindicator bottom">
                  <v-icon large color="rgba(0,0,0,.2)">mdi-menu-down</v-icon>
                </div>
                <v-row
                  class="selectRow pa-0 ma-0"
                  no-gutters
                  v-for="(item, index) in this.hierarchien('Daten', 3)"
                  :key="index"
                  @click="setHierarchy('Daten', 3, item.value, item.darstellung, item.einheit)"
                >
                  <v-col cols="8">
                    <v-icon
                      v-if="item.value + item.einheit != hierarchie3['Daten'] + hierarchie3Einheit"
                      small
                      class="ml-2 mr-2"
                      >mdi-radiobox-blank</v-icon
                    >
                    <v-icon
                      v-if="item.value + item.einheit == hierarchie3['Daten'] + hierarchie3Einheit"
                      small
                      class="ml-2 mr-2"
                      >mdi-radiobox-marked</v-icon
                    >
                    <span>{{ item.value }}{{ item.einheit }}</span></v-col
                  >
                  <v-col cols="4" align="right">
                    <div class="regionMarker" :class="{ active: item.bl == 1 }">BL</div>
                    <div class="regionMarker" :class="{ active: item.bfi == 1 }">BFI</div>
                    <div class="regionMarker" :class="{ active: item.gem == 1 }">G</div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="ma-3">
                  <v-col cols="4"
                    ><div class="regionMarker active">BL</div>
                    <div class="markerLegend">Bundesland</div></v-col
                  >
                  <v-col cols="4"
                    ><div class="regionMarker active">BFI</div>
                    <div class="markerLegend">Bezirksforst-<br />inspektion</div></v-col
                  >
                  <v-col cols="4"
                    ><div class="regionMarker active">G</div>
                    <div class="markerLegend">Gemeinde</div></v-col
                  >
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="pa-0 ma-0">
            <v-expansion-panel-header class="pa-0 ma-0 selectHead bottom">
              <div class="label">REGION</div>
              <span>{{ verwaltungsebene }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="pa-0 pr-2 pb-2 ma-0 selectContent"
              :style="'min-height:' + Math.min(5, regionenListe.length) * 30 + 'px'"
            >
              <v-row
                class="selectRow pa-0 ma-0"
                no-gutters
                v-for="(item, index) in regionenListe"
                :key="index"
                @click="setPanelItem('verwaltungsebene', item.value)"
              >
                <v-col cols="12">
                  <v-icon v-if="item.value != verwaltungsebene" small class="ml-2 mr-2">mdi-radiobox-blank</v-icon>
                  <v-icon v-if="item.value == verwaltungsebene" small class="ml-2 mr-2">mdi-radiobox-marked</v-icon>
                  <span>{{ item.value }}</span></v-col
                >
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
      <v-tab-item key="Raster" class="px-0" :class="{ 'py-2': !$store.state.isMobile }">
        <v-expansion-panels
          v-if="Number($route.params.datamode) == 1 && rasterMaps"
          :value="0"
          flat
          class="pa-0 ma-0"
          :class="{
            'mt-2': !$store.state.isMobile,
            belowMinHeight: $store.state.belowMinHeight,
            isMobile: $store.state.isMobile
          }"
        >
          <v-expansion-panel class="pa-0 ma-0">
            <v-expansion-panel-header class="pa-0 ma-0 selectHead bottom">
              <div class="label">THEMENKARTE</div>
              <span>{{ this.rasterMap }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="pa-0 pb-2 ma-0 selectContent"
              :style="'min-height:' + Math.min(5, rasterMaps.length) * 30 + 'px'"
            >
              <v-row
                class="selectRow pa-0 ma-0"
                no-gutters
                v-for="(item, index) in this.rasterMaps"
                :key="index"
                @click="setPanelRasterLayer(item)"
              >
                <v-col cols="12">
                  <v-icon v-if="item.name != rasterMap" small class="ml-2 mr-2">mdi-radiobox-blank</v-icon>
                  <v-icon v-if="item.name == rasterMap" small class="ml-2 mr-2">mdi-radiobox-marked</v-icon>
                  <span>{{ item.name }}</span></v-col
                >
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>

      <v-tab-item key="Tabellen" class="px-0" :class="{ 'py-2': !$store.state.isMobile }">
        <v-expansion-panels
          v-if="Number($route.params.datamode) == 2 && erg && $store.state.expertdata[erg]"
          v-model="panelIndex"
          flat
          class="pa-0 ma-0"
          :class="{
            'mt-2': !$store.state.isMobile,
            belowMinHeight: $store.state.belowMinHeight,
            isMobile: $store.state.isMobile
          }"
        >
          <v-expansion-panel class="pa-0 ma-0">
            <v-expansion-panel-header class="selectHead">
              <div class="label">PERIODE</div>
              <span>{{ ergListe.find((el) => el.value == erg).text }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="pa-0 pb-2 ma-0 selectContent"
              :style="
                'min-height:' +
                Math.min(5, ergListe.filter((e) => noTablesErgListe.indexOf(e.value) == -1).length) * 30 +
                'px'
              "
            >
              <v-row
                class="selectRow pa-0 ma-0"
                no-gutters
                v-for="(item, index) in this.ergListe.filter((e) => noTablesErgListe.indexOf(e.value) == -1)"
                :key="index"
                @click="setPanelItem('erg', item.value)"
              >
                <v-col cols="12">
                  <v-icon v-if="item.value != erg" small class="ml-2 mr-2">mdi-radiobox-blank</v-icon>
                  <v-icon v-if="item.value == erg" small class="ml-2 mr-2">mdi-radiobox-marked</v-icon>
                  <span>{{ item.text }}</span></v-col
                >
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="pa-0 ma-0" @change="scrollInit('tabellen', 1)">
            <v-expansion-panel-header class="selectHead">
              <div class="label">THEMA</div>
              <span>{{ hierarchie1["Tabellen"] }}{{ hierarchie1Einheit }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="pa-0 pr-2 ma-0 selectContent"
              id="h1tabellen"
              @scroll.native="handleScroll"
              :style="'min-height:' + Math.min(5, hierarchien('Tabellen', 1).length) * 30 + 'px'"
            >
              <div class="fullContent">
                <div class="scrollindicator top"><v-icon large color="rgba(0,0,0,.2)">mdi-menu-up</v-icon></div>
                <div class="scrollindicator bottom">
                  <v-icon large color="rgba(0,0,0,.2)">mdi-menu-down</v-icon>
                </div>
                <v-row
                  class="selectRow pa-0 ma-0"
                  no-gutters
                  v-for="(item, index) in this.hierarchien('Tabellen', 1)"
                  :key="index"
                  @click="setHierarchy('Tabellen', 1, item.value, item.darstellung, item.einheit)"
                >
                  <v-col cols="8">
                    <v-icon
                      v-if="item.value + item.einheit != hierarchie1['Tabellen'] + hierarchie1Einheit"
                      small
                      class="ml-2 mr-2"
                      >mdi-radiobox-blank</v-icon
                    >
                    <v-icon
                      v-if="item.value + item.einheit == hierarchie1['Tabellen'] + hierarchie1Einheit"
                      small
                      class="ml-2 mr-2"
                      >mdi-radiobox-marked</v-icon
                    >
                    <span>{{ item.value }}{{ item.einheit }}</span></v-col
                  >
                  <v-col cols="4" align="right">
                    <div class="regionMarker" :class="{ active: item.bl == 1 }">BL</div>
                    <div class="regionMarker" :class="{ active: item.bfi == 1 }">BFI</div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="ma-3">
                  <v-col cols="4"
                    ><div class="regionMarker active">BL</div>
                    <div class="markerLegend">Bundesland</div></v-col
                  >
                  <v-col cols="4"
                    ><div class="regionMarker active">BFI</div>
                    <div class="markerLegend">Bezirksforst-<br />inspektion</div></v-col
                  >
                  <v-col cols="4"></v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="pa-0 ma-0" @change="scrollInit('tabellen', 2)">
            <v-expansion-panel-header class="selectHead">
              <div class="label">KATEGORIE</div>
              <span>{{ hierarchie2["Tabellen"] }}{{ hierarchie2Einheit }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="pa-0 pr-2 ma-0 selectContent"
              id="h2tabellen"
              @scroll.native="handleScroll"
              :style="'min-height:' + Math.min(5, hierarchien('Tabellen', 2).length) * 30 + 'px'"
            >
              <div class="fullContent">
                <div class="scrollindicator top"><v-icon large color="rgba(0,0,0,.2)">mdi-menu-up</v-icon></div>
                <div class="scrollindicator bottom">
                  <v-icon large color="rgba(0,0,0,.2)">mdi-menu-down</v-icon>
                </div>
                <v-row
                  class="selectRow pa-0 ma-0"
                  no-gutters
                  v-for="(item, index) in this.hierarchien('Tabellen', 2)"
                  :key="index"
                  @click="setHierarchy('Tabellen', 2, item.value, item.darstellung, item.einheit)"
                >
                  <v-col cols="8">
                    <v-icon
                      v-if="item.value + item.einheit != hierarchie2['Tabellen'] + hierarchie2Einheit"
                      small
                      class="ml-2 mr-2"
                      >mdi-radiobox-blank</v-icon
                    >
                    <v-icon
                      v-if="item.value + item.einheit == hierarchie2['Tabellen'] + hierarchie2Einheit"
                      small
                      class="ml-2 mr-2"
                      >mdi-radiobox-marked</v-icon
                    >
                    <span>{{ item.value }}{{ item.einheit }}</span></v-col
                  >
                  <v-col cols="4" align="right">
                    <div class="regionMarker" :class="{ active: item.bl == 1 }">BL</div>
                    <div class="regionMarker" :class="{ active: item.bfi == 1 }">BFI</div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="ma-3">
                  <v-col cols="4"
                    ><div class="regionMarker active">BL</div>
                    <div class="markerLegend">Bundesland</div></v-col
                  >
                  <v-col cols="4"
                    ><div class="regionMarker active">BFI</div>
                    <div class="markerLegend">Bezirksforst-<br />inspektion</div></v-col
                  >
                  <v-col cols="4"></v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            :class="{ hiddenPanel: !isHierarchie3 }"
            class="pa-0 ma-0"
            @change="scrollInit('tabellen', 3)"
          >
            <v-expansion-panel-header class="selectHead">
              <div class="label">UNTERKATEGORIE</div>
              <span>{{ hierarchie3["Tabellen"] }}{{ hierarchie3Einheit }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="pa-0 pr-2 ma-0 selectContent"
              id="h3tabellen"
              @scroll.native="handleScroll"
              :style="'min-height:' + Math.min(5, hierarchien('Tabellen', 3).length) * 30 + 'px'"
            >
              <div class="fullContent">
                <div class="scrollindicator top"><v-icon large color="rgba(0,0,0,.2)">mdi-menu-up</v-icon></div>
                <div class="scrollindicator bottom">
                  <v-icon large color="rgba(0,0,0,.2)">mdi-menu-down</v-icon>
                </div>
                <v-row
                  class="selectRow pa-0 ma-0"
                  no-gutters
                  v-for="(item, index) in this.hierarchien('Tabellen', 3)"
                  :key="index"
                  @click="setHierarchy('Tabellen', 3, item.value, item.darstellung, item.einheit)"
                >
                  <v-col cols="8">
                    <v-icon
                      v-if="item.value + item.einheit != hierarchie3['Tabellen'] + hierarchie3Einheit"
                      small
                      class="ml-2 mr-2"
                      >mdi-radiobox-blank</v-icon
                    >
                    <v-icon
                      v-if="item.value + item.einheit == hierarchie3['Tabellen'] + hierarchie3Einheit"
                      small
                      class="ml-2 mr-2"
                      >mdi-radiobox-marked</v-icon
                    >
                    <span>{{ item.value }}{{ item.einheit }}</span></v-col
                  >
                  <v-col cols="4" align="right">
                    <div class="regionMarker" :class="{ active: item.bl == 1 }">BL</div>
                    <div class="regionMarker" :class="{ active: item.bfi == 1 }">BFI</div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="ma-3">
                  <v-col cols="4"
                    ><div class="regionMarker active">BL</div>
                    <div class="markerLegend">Bundesland</div></v-col
                  >
                  <v-col cols="4"
                    ><div class="regionMarker active">BFI</div>
                    <div class="markerLegend">Bezirksforst-<br />inspektion</div></v-col
                  >
                  <v-col cols="4"></v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="pa-0 ma-0">
            <v-expansion-panel-header class="pa-0 ma-0 selectHead bottom">
              <div class="label">REGION</div>
              <span>{{ verwaltungsebene }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="pa-0 pb-2 ma-0 selectContent"
              :style="'min-height:' + Math.min(5, regionenListe.length) * 30 + 'px'"
            >
              <v-row
                class="selectRow pa-0 ma-0"
                no-gutters
                v-for="(item, index) in regionenListe"
                :key="index"
                @click="setPanelItem('verwaltungsebene', item.value)"
              >
                <v-col cols="12">
                  <v-icon v-if="item.value != verwaltungsebene" small class="ml-2 mr-2">mdi-radiobox-blank</v-icon>
                  <v-icon v-if="item.value == verwaltungsebene" small class="ml-2 mr-2">mdi-radiobox-marked</v-icon>
                  <span>{{ item.value }}</span></v-col
                >
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import axios from "redaxios";
import parseCsv from "csv-parse/lib/sync";
import { regionDataLayer, setRasterLayer, setVectorLayer, setEmptyLayer, map, zoomToFullExtent } from "../mixins/map";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import {
  colorWithImpliedOpacity,
  layerByErg,
  lightPatternForZoom,
  precise_round,
  getColorForValue,
  hexToRgb
} from "../utils/style";
import TheSearch from "./TheSearch.vue";
import { DATA_DIR_PREFIX } from "../utils/constants";

const stroke = new Stroke({
  color: "green",
  width: 1.5
});
const style = new Style({
  stroke: stroke,
  fill: new Fill({
    color: "#455a4e"
    // color: "#24312a"
  })
});
const outlineStyle = new Style({
  zIndex: 3,
  stroke: new Stroke({
    color: "white",
    width: 1.5
  })
});
const austriaStyle = new Style({
  stroke: new Stroke({
    color: "#455a4e",
    width: 5
  })
});

export default {
  name: "TheTopicSelector",
  components: {
    TheSearch
  },
  data: () => ({
    noTables: false,
    hierachySet: 0,
    colorByMode: ["#447681", "#78a02c", "#ac2a28"],
    isLoading: false,
    panelIndex: -1,
    /** @type {Array<import("../mixins/map.js").RasterLayerData>} */
    rasterMaps: null,
    rasterMap: null,
    dataMode: 0,
    jsonLoaded: false,
    dataModeString: ["Daten", "Raster", "Tabellen"],
    ergs: {},
    erg: null,
    ergLoaded: null,
    /** @type {Array<string>} */
    noTablesErgListe: [],
    /** @type {Array<{value: string, text: string}>} */
    ergListe: null,
    hierarchie1: { Daten: null, Tabellen: null },
    hierarchie2: { Daten: null, Tabellen: null },
    hierarchie3: { Daten: null, Tabellen: null },
    hierarchie1Darstellung: { Daten: "Prozent", Tabellen: "Prozent" },
    hierarchie2Darstellung: { Daten: "Prozent", Tabellen: "Prozent" },
    hierarchie3Darstellung: { Daten: "Prozent", Tabellen: "Prozent" },
    hierarchie1Einheit: "",
    hierarchie2Einheit: "",
    hierarchie3Einheit: "",
    verwaltungsebenen: ["Österreich", "Bundesland", "Bezirksforstinspektion", "Gemeinde"],
    verwaltungsebene: "Bundesland",
    attribute: null,
    attributeTab: null,
    mapColor: [100, 100, 100, 1],
    filter: null
  }),
  async created() {
    this.isLoading = true;
    const responses = await Promise.all([
      axios("./data/erhebungen.txt"),
      axios("./data/rasterdaten.txt"),
      axios("./data/waldwissen.txt"),
      axios("./data/erg_daten_lookup.txt")
    ]);
    this.isLoading = false;
    const [erhebungen, rasterMaps, waldwissen, datenlookup] = responses.map((response) =>
      parseCsv(response.data, {
        delimiter: ";",
        columns: true,
        trim: true
      })
    );
    this.$store.dispatch("waldwissen", waldwissen);
    this.$store.dispatch("datenlookup", datenlookup);
    this.rasterMaps = rasterMaps;

    for (let i = 0, ii = rasterMaps.length; i < ii; i++) {
      const rasterMap = rasterMaps[i];
      if (rasterMap.legendUrl && rasterMap.legendUrl.slice(-4) === ".txt") {
        try {
          const response = await axios(DATA_DIR_PREFIX + rasterMap.legendUrl);
          const legendItems = parseCsv(response.data, {
            delimiter: ";",
            columns: true,
            trim: true
          });
          legendItems.forEach((legendItem) => {
            legendItem.rgb = this.hexToRgb(legendItem.farbe).slice(0, 3);
          });
          rasterMap.legendItems = legendItems;
        } catch (e) {
          // possible wrong legend-path / missing legend file
        }
      }
    }
    this.ergListe = [];
    let ergDefault = null;
    this.noTablesErgListe = [];
    for (let erh = 0; erh < erhebungen.length; erh++) {
      this.ergs[erhebungen[erh].Key] = erhebungen[erh].Zeitraum;
      if (erhebungen[erh].Default && erhebungen[erh].Default === "1") {
        ergDefault = erhebungen[erh].Key;
      }
      this.ergListe.push({
        text: erhebungen[erh].Zeitraum,
        value: erhebungen[erh].Key
      });
      if (erhebungen[erh].Tabellen == "0") {
        this.noTablesErgListe.push(erhebungen[erh].Key);
      }
    }
    this.$store.dispatch("ergs", this.ergs);
    this.updateFilter();
    this.ergListe.reverse();

    const defaultIndex = ergDefault ? this.ergListe.map((e) => e.value).indexOf(ergDefault) : 0;

    const allBfiLookUp = {};
    const allMetadata = {};
    const AllExpertdata = {};
    for (let e = 0; e < this.ergListe.length; e++) {
      let response;
      response = await axios(`./data/${this.ergListe[e].value}/datajson/lbfi_lookup.json`);
      allBfiLookUp[this.ergListe[e].value] = response.data.filter((e) => e.Lbfi < 1000);

      response = await axios(
        `./data/${this.ergListe[e].value}/${this.ergListe[e].value}_daten_lookup_legende_hierarchie.txt`
      );
      allMetadata[this.ergListe[e].value] = parseCsv(response.data, { delimiter: ";", columns: true, trim: true });

      response = await axios(
        `./data/${this.ergListe[e].value}/${this.ergListe[e].value}_tabellen_lookup_legende_hierarchie.txt`
      );
      AllExpertdata[this.ergListe[e].value] = parseCsv(response.data, { delimiter: ";", columns: true, trim: true });
    }
    this.$store.dispatch("bfilookup", Object.freeze(allBfiLookUp));
    this.$store.dispatch("metadata", Object.freeze(allMetadata));
    this.$store.dispatch("expertdata", Object.freeze(AllExpertdata));

    this.dataMode = Number(this.$route.params.datamode);
    if (this.$route.params.attribute.startsWith("m")) {
      this.setAttributeFromRoute();
    }
    this.verwaltungsebene = this.$route.params.verwaltungsebene;
    this.erg = this.$route.params.erg || (this.dataMode != 1 ? this.ergListe[defaultIndex].value : undefined);
    this.$store.dispatch("periode", this.erg);
  },
  watch: {
    "$route.params.erg"(value) {
      this.erg = value;
      this.$store.dispatch("periode", this.erg);
    },
    "$route.params.attribute"() {
      this.setAttributeFromRoute();
    },
    "$route.params.datamode"(value) {
      const transparency = value === 0 ? 80 : 60;
      this.$store.dispatch("opacity", transparency / 100);
      zoomToFullExtent();
      this.dataMode = Number(value);
    },
    "$route.params.verwaltungsebene"(value) {
      if (value && this.verwaltungsebene != value) {
        this.verwaltungsebene = value;
      }
    },
    "$store.state.quantiles"() {
      this.applyStyle();
    },
    dataMode(value) {
      const transparency = value === 0 ? 80 : 60;
      this.$store.dispatch("opacity", transparency / 100);
      this.$store.dispatch("mobileFilterOpen", false);
      this.panelIndex = -1;
      this.$store.dispatch("selectedRegion", null);
      if (value != this.$route.params.datamode) {
        this.$router.push({
          params: {
            erg: this.erg,
            datamode: value,
            region: undefined,
            attribute: (this.dataMode === 0 ? this.attribute : this.attributeTab) || "-"
          }
        });
      }
      switch (value) {
        case 0: {
          this.setVectorLayer();
          this.erg = this.$route.params.erg || this.ergListe[0].value;
          this.verwaltungsebene = this.$route.params.verwaltungsebene;
          if (this.$store.state.data && this.jsonLoaded) {
            this.applyStyle();
          }
          break;
        }
        case 1: {
          let rasterMap;
          if (!this.rasterMap) {
            rasterMap = this.rasterMaps[0];
          } else {
            rasterMap = this.rasterMaps.find((el) => el.name == this.rasterMap);
          }
          this.setRasterLayer(rasterMap);
          this.erg = this.$route.params.erg || this.ergListe[0].value;
          this.verwaltungsebene = undefined;
          this.rasterMap = rasterMap.name;
          break;
        }
        case 2: {
          this.setEmptyLayer();
          this.attribute = null;
          this.erg = this.$route.params.erg || this.ergListe[0].value;
          this.verwaltungsebene = this.$route.params.verwaltungsebene;
          if (this.verwaltungsebene == "Österreich") {
            this.$router.push({ params: { region: "0" } });
          }
          break;
        }
        default:
          throw new Error("Unknown data mode");
      }
    },
    async erg(value) {
      if (
        this.$route.params.region &&
        !this.$store.state.bfilookup[this.erg].find((elem) => elem.Lbfi == this.$route.params.region)
      ) {
        this.$store.dispatch("bfiwarning", Number(this.$route.params.region) >= 10000 ? 2 : 1);
        this.$store.dispatch("selectedRegion", null);
        this.$router.push({ params: { region: undefined } });
      }
      this.$store.dispatch("mobileFilterOpen", false);
      if (!value) {
        return;
      }
      this.$store.dispatch("periode", this.erg);

      if (this.$route.params.erg != value) {
        this.$router.push({ params: { erg: value } });
      }

      this.setAttributeFromRoute();

      const transparency = this.dataMode === 0 ? 80 : 60;
      this.$store.dispatch("opacity", transparency / 100);
    },
    attribute(value) {
      const metadata = this.$store.state.metadata[this.erg];
      if (value) {
        const routeMeta = metadata.find((e) => e.Kodierung_ID == value);
        this.hierarchie1Darstellung["Daten"] = routeMeta["Darstellung(Prozent/Wert)"];
        this.hierarchie1["Daten"] = routeMeta.Hierarchie_1;
        this.hierarchie2Darstellung["Daten"] = routeMeta["Darstellung(Prozent/Wert)"];
        this.hierarchie2["Daten"] = routeMeta.Hierarchie_2;
        this.hierarchie3["Daten"] = routeMeta.Hierarchie_3;
        if (routeMeta.Hierarchie_3 != "") {
          this.hierarchie3Einheit = " (" + routeMeta.Einheit + ")";
          this.hierarchie3Darstellung["Daten"] = routeMeta["Darstellung(Prozent/Wert)"];
          this.hierarchie3["Daten"] = routeMeta.Hierarchie_3;
        } else {
          this.hierarchie2Einheit = " (" + routeMeta.Einheit + ")";
        }
        this.mapColor = this.hexToRgb("#" + routeMeta.Farbe);
      } else {
        this.hierarchie1["Daten"] = metadata[0].Hierarchie_1;
      }
      this.$store.dispatch(
        "attributeDarstellung",
        !this.hierarchie3["Daten"] || this.hierarchie3["Daten"] == ""
          ? this.hierarchie2Darstellung["Daten"]
          : this.hierarchie3Darstellung["Daten"]
      );

      const routeParam = value ? `r${value}` : "-";
      if (this.$route.params.attribute != routeParam) {
        this.$router.push({ params: { attribute: routeParam } });
      }
      this.$store.dispatch("attribute", value);
    },
    attributeTab(value) {
      const expertdata = this.$store.state.expertdata[this.erg];
      if (value) {
        const routeMeta = expertdata.find((e) => e.Kodierung_ID == value);
        this.hierarchie1Darstellung["Tabellen"] = routeMeta["Darstellung(Prozent/Wert)"];
        this.hierarchie1["Tabellen"] = routeMeta.Hierarchie_1;
        this.hierarchie2Darstellung["Tabellen"] = routeMeta["Darstellung(Prozent/Wert)"];
        this.hierarchie2["Tabellen"] = routeMeta.Hierarchie_2;
        this.hierarchie3["Tabellen"] = routeMeta.Hierarchie_3;
        if (routeMeta.Hierarchie_3 != "") {
          this.hierarchie3Einheit = " (" + routeMeta.Einheit + ")";
          this.hierarchie3Darstellung["Tabellen"] = routeMeta["Darstellung(Prozent/Wert)"];
          this.hierarchie3["Tabellen"] = routeMeta.Hierarchie_3;
        } else {
          this.hierarchie2Einheit = " (" + routeMeta.Einheit + ")";
        }
      } else {
        this.hierarchie1["Tabellen"] = expertdata[0].Hierarchie_1;
      }

      const routeParam = value ? `t${value}` : "-";
      if (this.$route.params.attribute != routeParam) {
        this.$router.push({ params: { attribute: routeParam } });
      }
      this.$store.dispatch("attributeTab", value);
      const attRow = this.$store.state.expertdata[this.erg].find((e) => e.Kodierung_ID == value);
      if (attRow) {
        const attString = attRow.BFIs_ohne_Daten.replace(/\s/g, "");
        this.$store.dispatch("tablesMissingBFIs", attString.length > 0 ? attString.split(",") : []);
        this.applyStyle();
      }
    },
    verwaltungsebene(value) {
      this.$router.push({ params: { region: undefined } });
      this.$store.dispatch("selectedRegion", null);
      if (value && this.$route.params.verwaltungsebene != value) {
        this.$router.push({ params: { verwaltungsebene: value } });
      }
      this.updateFilter();
      this.applyStyle();
    }
  },
  methods: {
    switchMobileFilter() {
      this.$store.dispatch("mobileFilterOpen", !this.$store.state.mobileFilterOpen);
    },
    scrollInit(sphere, nr) {
      window.setTimeout(() => {
        this.handleScroll(0, document.getElementById("h" + nr + sphere));
      }, 700);
    },
    handleScroll(event, direct = null) {
      if (direct) {
        event = { target: direct };
      }
      if (event.target) {
        const childHeight = event.target.children[0].children[0].offsetHeight;
        const myHeight = event.target.offsetHeight;
        const scrTop = event.target.scrollTop;

        if (scrTop > 30) {
          event.target.classList.add("onTop");
        } else {
          event.target.classList.remove("onTop");
        }
        if (scrTop < childHeight - myHeight) {
          event.target.classList.add("onBottom");
        } else {
          event.target.classList.remove("onBottom");
        }
      }
    },
    setRasterLayer,
    setVectorLayer,
    setEmptyLayer,
    setPanelRasterLayer(item) {
      this.$store.dispatch("mobileFilterOpen", false);
      setRasterLayer(item);
      this.rasterMap = item.name;
      this.panelIndex = -1;
    },
    setPanelItem(key, value) {
      this[key] = value;
      this.panelIndex = -1;
    },
    setHierarchy(sphere, level, value, darstellung, einheit) {
      this.$store.dispatch("mobileFilterOpen", false);
      this.hierachySet = level;
      for (let l = level + 1; l < 4; l++) {
        this["hierarchie" + l][sphere] = null;
      }
      if (sphere == "Daten") {
        this["hierarchie" + level + "Darstellung"][sphere] = darstellung;
      }
      this["hierarchie" + level + "Einheit"] = einheit;
      this["hierarchie" + level][sphere] = value;
      this.panelIndex = -1;
    },
    async setAttributeFromRoute() {
      const regionSave = this.$route.params.region;
      const verwaltungsebeneSave = this.$route.params.verwaltungsebene;
      if (this.$route.params.datamode == "0") {
        this.$router.push({ params: { region: undefined } });
        this.$store.dispatch("selectedRegion", null);
      }
      const routeAttribute = this.$route.params.attribute;

      if (routeAttribute.startsWith("r")) {
        this.jsonLoaded = false;
        this.isLoading = true;
        const response = await axios.get(`./data/${this.erg}/datajson/${routeAttribute.substring(1)}.json`);
        const data = [];
        for (let i = 0; i < response.data.length; i++) {
          data[response.data[i].Lbfi] = response.data[i];
        }
        this.$store.dispatch("data", data);
        this.isLoading = false;
        this.attribute = routeAttribute.substring(1);
        this.$nextTick(this.applyStyle);
        this.jsonLoaded = true;

        if (this.$route.params.verwaltungsebene === verwaltungsebeneSave) {
          this.$router.push({ params: { region: regionSave } });
        }
      } else if (routeAttribute.startsWith("t")) {
        this.attributeTab = routeAttribute.substring(1);
      } else if (routeAttribute.startsWith("m")) {
        this.rasterMap = routeAttribute.substring(1);
      }
    },
    hexToRgb,
    updateFilter() {
      switch (this.verwaltungsebene) {
        case "Keine":
          this.filter = (feature) => feature.get("lbfi") < 0;
          break;
        case "Österreich":
          this.filter = (feature) => feature.get("lbfi") === 0;
          this.$router.push({ params: { region: "0" } });
          break;
        case "Bundesland":
          this.filter = (feature) => feature.get("lbfi") >= 1 && feature.get("lbfi") <= 9;
          break;
        case "Bezirksforstinspektion":
          this.filter = (feature) => feature.get("lbfi") >= 10 && feature.get("lbfi") <= 999;
          break;
        case "Gemeinde":
          this.filter = (feature) => feature.get("lbfi") >= 1000;
          break;
        default:
          this.filter = () => false;
      }
    },
    regionen(category) {
      const metadata =
        category == "Daten" ? this.$store.state.metadata[this.erg] : this.$store.state.expertdata[this.erg];
      if (!metadata || !this.erg || this.$route.params.datamode == "1") {
        return [];
      }
      const tempListe = [];

      const attr = !this.hierarchie3[category]
        ? metadata.find(
            (record) =>
              record.Hierarchie_1 == this.hierarchie1[category] &&
              record.Hierarchie_2 == this.hierarchie2[category] &&
              ((category == "Daten" && record["Darstellung(Prozent/Wert)"] == this.hierarchie2Darstellung[category]) ||
                (category == "Tabellen" && record.Einheit == this.hierarchie2Einheit.replace(/\s\(|\)/g, "")))
          )
        : metadata.find(
            (record) =>
              record.Hierarchie_1 == this.hierarchie1[category] &&
              record.Hierarchie_2 == this.hierarchie2[category] &&
              record.Hierarchie_3 == this.hierarchie3[category] &&
              ((category == "Daten" && record["Darstellung(Prozent/Wert)"] == this.hierarchie3Darstellung[category]) ||
                (category == "Tabellen" && record.Einheit == this.hierarchie3Einheit.replace(/\s\(|\)/g, "")))
          );
      if (typeof attr != "undefined") {
        if (attr.Bund == 1) {
          tempListe.push({ value: "Österreich" });
        }
        if (attr.Bundesland == 1) {
          tempListe.push({ value: "Bundesland" });
        }
        if (attr.BFI == 1) {
          tempListe.push({ value: "Bezirksforstinspektion" });
        }
        if (attr.Gemeinde == 1) {
          tempListe.push({ value: "Gemeinde" });
        }
      }
      if (tempListe.length > 0) {
        if (!tempListe.find((e) => e.value == this.verwaltungsebene)) {
          this.verwaltungsebene = tempListe[tempListe.length - 1].value;
          this.$store.dispatch("selectedRegion", null);
        }
      }
      return tempListe;
    },
    hierarchien(category, level) {
      if (
        this.isLoading ||
        !this.erg ||
        (category == "Daten" && this.$route.params.datamode != "0") ||
        (category == "Tabellen" && this.$route.params.datamode != "2")
      ) {
        return [];
      }

      const returnValues = [];
      const checkValues = [];
      const metadata =
        category == "Daten" ? this.$store.state.metadata[this.erg] : this.$store.state.expertdata[this.erg];
      switch (level) {
        case 1:
          metadata.forEach((element) => {
            element.Bund = element.Bund == "" ? "0" : element.Bund; // Korektur für unsaubere Daten!
            element.Bundesland = element.Bundesland == "" ? "0" : element.Bundesland; // Korektur für unsaubere Daten!
            element.BFI = element.BFI == "" ? "0" : element.BFI; // Korektur für unsaubere Daten!
            element.Gemeinde = element.Gemeinde == "" ? "0" : element.Gemeinde; // Korektur für unsaubere Daten!
            if (category == "Tabellen") {
              element.Anzeigen = element.Bund == "0" && element.Bundesland == "0" && element.BFI == "0" ? 0 : 1;
              element.Gemeinde = 0;
              element["Darstellung(Prozent/Wert)"] = element.Einheit;
            }

            if (element.Anzeigen == "1" && element.Hierarchie_1 != "") {
              const ohneKinder = metadata.find(
                (e) => e.Hierarchie_1 == element.Hierarchie_1 && e.Hierarchie_2 == "" && e.Hierarchie_3 == ""
              );
              const childBL = metadata.find(
                (e) => e.Hierarchie_1 == element.Hierarchie_1 && e.Hierarchie_2 != "" && e.Bundesland == "1"
              )
                ? true
                : false;
              const childBFI = metadata.find(
                (e) => e.Hierarchie_1 == element.Hierarchie_1 && e.Hierarchie_2 != "" && e.BFI == "1"
              )
                ? true
                : false;
              const childG = metadata.find(
                (e) => e.Hierarchie_1 == element.Hierarchie_1 && e.Hierarchie_2 != "" && e.Gemeinde == "1"
              )
                ? true
                : false;
              if (checkValues.indexOf(element.Hierarchie_1) == -1) {
                returnValues.push({
                  value: element.Hierarchie_1,
                  einheit: ohneKinder ? " (" + element.Einheit + ")" : "",
                  bl: childBL ? "1" : element.Bundesland,
                  bfi: childBFI ? "1" : element.BFI,
                  gem: childG ? "1" : element.Gemeinde,
                  darstellung: element["Darstellung(Prozent/Wert)"]
                });
                checkValues.push(element.Hierarchie_1);
              } else {
                if (
                  ohneKinder &&
                  returnValues.find((e) => e.value == element.Hierarchie_1).darstellung !=
                    element["Darstellung(Prozent/Wert)"]
                ) {
                  returnValues.push({
                    value: element.Hierarchie_1,
                    einheit: " (" + element.Einheit + ")",
                    bl: element.Bundesland,
                    bfi: element.BFI,
                    gem: element.Gemeinde,
                    darstellung: element["Darstellung(Prozent/Wert)"]
                  });
                }
              }
            }
          });
          if (returnValues.length > 0) {
            this.hierarchie1Einheit = !this.hierarchie1[category] ? returnValues[0].einheit : this.hierarchie1Einheit;
            this.hierarchie1[category] = !this.hierarchie1[category]
              ? returnValues[0].value
              : this.hierarchie1[category];
          }
          break;
        case 2:
          metadata
            .filter((record) => record.Hierarchie_1 == this.hierarchie1[category])
            .forEach((element) => {
              element.Bund = element.Bund == "" ? "0" : element.Bund; // Korektur für unsaubere Daten!
              element.Bundesland = element.Bundesland == "" ? "0" : element.Bundesland; // Korektur für unsaubere Daten!
              element.BFI = element.BFI == "" ? "0" : element.BFI; // Korektur für unsaubere Daten!
              element.Gemeinde = element.Gemeinde == "" ? "0" : element.Gemeinde; // Korektur für unsaubere Daten!
              if (category == "Tabellen") {
                element.Anzeigen = element.Bund == "0" && element.Bundesland == "0" && element.BFI == "0" ? 0 : 1;
                element.Gemeinde = 0;
                element["Darstellung(Prozent/Wert)"] = element.Einheit;
              }

              if (element.Anzeigen == "1" && element.Hierarchie_2 != "") {
                const ohneKinder = metadata.find((e) => e.Hierarchie_2 == element.Hierarchie_2 && e.Hierarchie_3 == "");
                const childBL = metadata.find(
                  (e) =>
                    e.Hierarchie_1 == element.Hierarchie_1 &&
                    e.Hierarchie_2 == element.Hierarchie_2 &&
                    e.Hierarchie_3 != "" &&
                    e.Bundesland == "1"
                )
                  ? true
                  : false;
                const childBFI = metadata.find(
                  (e) =>
                    e.Hierarchie_1 == element.Hierarchie_1 &&
                    e.Hierarchie_2 == element.Hierarchie_2 &&
                    e.Hierarchie_3 != "" &&
                    e.BFI == "1"
                )
                  ? true
                  : false;
                const childG = metadata.find(
                  (e) =>
                    e.Hierarchie_1 == element.Hierarchie_1 &&
                    e.Hierarchie_2 == element.Hierarchie_2 &&
                    e.Hierarchie_3 != "" &&
                    e.Gemeinde == "1"
                )
                  ? true
                  : false;
                if (checkValues.indexOf(element.Hierarchie_2) == -1) {
                  returnValues.push({
                    value: element.Hierarchie_2,
                    einheit: ohneKinder ? " (" + element.Einheit + ")" : "",
                    bl: childBL ? "1" : element.Bundesland,
                    bfi: childBFI ? "1" : element.BFI,
                    gem: childG ? "1" : element.Gemeinde,
                    darstellung: element["Darstellung(Prozent/Wert)"]
                  });
                  checkValues.push(element.Hierarchie_2);
                } else {
                  if (
                    ohneKinder &&
                    element["Darstellung(Prozent/Wert)"] !=
                      returnValues.find((e) => e.value == element.Hierarchie_2).darstellung
                  ) {
                    returnValues.push({
                      value: element.Hierarchie_2,
                      einheit: " (" + element.Einheit + ")",
                      bl: element.Bundesland,
                      bfi: element.BFI,
                      gem: element.Gemeinde,
                      darstellung: element["Darstellung(Prozent/Wert)"]
                    });
                  }
                }
              }
            });
          if (returnValues.length > 0) {
            this.hierarchie2Einheit =
              !this.hierarchie2[category] ||
              typeof returnValues.find(
                (el) => el.value == this.hierarchie2[category] && el.einheit == this.hierarchie2Einheit
              ) == "undefined"
                ? returnValues[0].einheit
                : this.hierarchie2Einheit;
            this.hierarchie2Darstellung[category] =
              !this.hierarchie2[category] ||
              typeof returnValues.find(
                (el) => el.value == this.hierarchie2[category] && el.einheit == this.hierarchie2Einheit
              ) == "undefined"
                ? returnValues[0].darstellung
                : this.hierarchie2Darstellung[category];
            this.hierarchie2[category] = !this.hierarchie2[category]
              ? returnValues[0].value
              : this.hierarchie2[category];
          }
          break;
        case 3:
          metadata
            .filter(
              (record) =>
                record.Hierarchie_1 == this.hierarchie1[category] &&
                record.Hierarchie_2 == this.hierarchie2[category] &&
                record.Hierarchie_3 != ""
            )
            .forEach((element) => {
              element.Bund = element.Bund == "" ? "0" : element.Bund; // Korektur für unsaubere Daten!
              element.Bundesland = element.Bundesland == "" ? "0" : element.Bundesland; // Korektur für unsaubere Daten!
              element.BFI = element.BFI == "" ? "0" : element.BFI; // Korektur für unsaubere Daten!
              element.Gemeinde = element.Gemeinde == "" ? "0" : element.Gemeinde; // Korektur für unsaubere Daten!
              if (category == "Tabellen") {
                element.Anzeigen = element.Bund == "0" && element.Bundesland == "0" && element.BFI == "0" ? 0 : 1;
                element.Gemeinde = 0;
                element["Darstellung(Prozent/Wert)"] = element.Einheit;
              }

              if (element.Anzeigen == "1" && element.Hierarchie_3 != "") {
                if (checkValues.indexOf(element.Hierarchie_3) == -1) {
                  returnValues.push({
                    value: element.Hierarchie_3,
                    einheit: " (" + element.Einheit + ")",
                    bl: element.Bundesland,
                    bfi: element.BFI,
                    gem: element.Gemeinde,
                    darstellung: element["Darstellung(Prozent/Wert)"]
                  });
                  checkValues.push(element.Hierarchie_3);
                } else {
                  if (
                    element["Darstellung(Prozent/Wert)"] !=
                    returnValues.find((e) => e.value == element.Hierarchie_3).darstellung
                  ) {
                    returnValues.push({
                      value: element.Hierarchie_3,
                      einheit: " (" + element.Einheit + ")",
                      bl: element.Bundesland,
                      bfi: element.BFI,
                      gem: element.Gemeinde,
                      darstellung: element["Darstellung(Prozent/Wert)"]
                    });
                  }
                }
              }
            });
          if (returnValues.length > 0) {
            this.hierarchie3Einheit =
              !this.hierarchie3[category] ||
              typeof returnValues.find((el) => el.value == this.hierarchie3[category]) == "undefined"
                ? returnValues[0].einheit
                : this.hierarchie3Einheit;
            this.hierarchie3Darstellung[category] =
              !this.hierarchie3[category] ||
              typeof returnValues.find((el) => el.value == this.hierarchie3[category]) == "undefined"
                ? returnValues[0].darstellung
                : this.hierarchie3Darstellung[category];
            this.hierarchie3[category] =
              !this.hierarchie3[category] ||
              typeof returnValues.find((el) => el.value == this.hierarchie3[category]) == "undefined"
                ? returnValues[0].value
                : this.hierarchie3[category];
          }
          break;
      }
      if (
        metadata.filter(
          (record) =>
            record.Hierarchie_1 == this.hierarchie1[category] &&
            record.Hierarchie_2 == this.hierarchie2[category] &&
            record.Hierarchie_3 != ""
        ).length == 0
      ) {
        this.hierarchie3[category] = null;
      }

      const attr = !this.hierarchie3[category]
        ? metadata.find(
            (record) =>
              record.Hierarchie_1 == this.hierarchie1[category] &&
              record.Hierarchie_2 == this.hierarchie2[category] &&
              ((category == "Daten" && record["Darstellung(Prozent/Wert)"] == this.hierarchie2Darstellung["Daten"]) ||
                (category == "Tabellen" && record.Einheit == this.hierarchie2Einheit.replace(/\s\(|\)/g, "")))
          )
        : metadata.find(
            (record) =>
              record.Hierarchie_1 == this.hierarchie1[category] &&
              record.Hierarchie_2 == this.hierarchie2[category] &&
              record.Hierarchie_3 == this.hierarchie3[category] &&
              ((category == "Daten" && record["Darstellung(Prozent/Wert)"] == this.hierarchie3Darstellung[category]) ||
                (category == "Tabellen" && record.Einheit == this.hierarchie3Einheit.replace(/\s\(|\)/g, "")))
          );
      const dataModeString = this.dataModeString[this.$route.params.datamode];
      //FIXME avoid side effects!
      this.$store.dispatch(
        "attributeName",
        !this.hierarchie3[dataModeString] || this.hierarchie3[dataModeString] == ""
          ? this.hierarchie1[dataModeString] + " | " + this.hierarchie2[dataModeString] + this.hierarchie2Einheit
          : this.hierarchie1[dataModeString] +
              " | " +
              this.hierarchie2[dataModeString] +
              " | " +
              this.hierarchie3[dataModeString] +
              this.hierarchie3Einheit
      );
      //FIXME avoid side effects!
      this.$store.dispatch(
        "attributeNameKurz",
        !this.hierarchie3[dataModeString] || this.hierarchie3[dataModeString] == ""
          ? this.hierarchie2[dataModeString] + this.hierarchie2Einheit
          : this.hierarchie3[dataModeString] + this.hierarchie3Einheit
      );
      if (category == "Daten") {
        //FIXME avoid side effects!
        this.attribute = (attr || metadata[0]).Kodierung_ID;
      }
      if (category == "Tabellen") {
        //FIXME avoid side effects!
        this.attributeTab = (attr || metadata[0]).Kodierung_ID;
      }

      // Automatisches Öffnen der letzten Hierarchie - Auf Wunsch AG deaktiviert
      /*
      if ( level > this.hierachySet && returnValues.length > 0) {
        const newIndex = level + 1;
        if (newIndex > this.panelIndex) {
          this.hierachySet = level;
          this.$nextTick(() => {
            this.panelIndex = newIndex;
            this.scrollInit(category.toLowerCase(), level);
          });
        }
      }
      */

      return returnValues;
    },
    applyStyle() {
      if (
        !(
          ((this.dataMode === 0 && this.jsonLoaded) || this.dataMode === 2) &&
          this.erg &&
          this.verwaltungsebene &&
          (this.attribute || this.attributeTab)
        )
      ) {
        return;
      }

      if (this.verwaltungsebene == "Österreich") {
        this.$router.push({ params: { region: "0" } });
      }

      const classes = [];

      if (this.dataMode === 0 && this.jsonLoaded) {
        const property =
          this.$store.state.attributeDarstellung == "Prozent" ? `${this.attribute}_Proz` : `${this.attribute}_Wert`;
        const allCurrentData = this.$store.state.data
          .filter((d) => typeof d[property] === "number")
          .map((o) => o[property])
          .sort((a, b) => a - b);
        const uniqueValues = allCurrentData.filter((value, index, array) => {
          return array.indexOf(value) === index;
        });
        const maxValue = uniqueValues[uniqueValues.length - 1];
        const minValue = uniqueValues[0];
        const numberOfClasses = uniqueValues.length < 5 ? uniqueValues.length : 5;
        const elementsPerClass = this.$store.state.quantiles ? Math.floor(uniqueValues.length / numberOfClasses) : null;
        // equal intervals

        // difference between min and max value
        const prozDifference = maxValue - minValue;
        // equal size numberOfClasses
        const classSize = prozDifference / numberOfClasses;
        let lastMaxValue = minValue;
        for (let i = 0, ii = numberOfClasses; i < ii; i++) {
          let currentMax;
          if (this.$store.state.quantiles) {
            currentMax =
              i === ii - 1 ? uniqueValues[uniqueValues.length - 1] : uniqueValues[elementsPerClass * (i + 1)];
          } else {
            currentMax = lastMaxValue + classSize;
          }

          const thisBounds = {
            min: lastMaxValue,
            max: currentMax
          };
          // round the bounds to nice values
          // the min and max bounds are specifically rounded down/upward, to prevent features
          // falling out of bounds due to rounding errors
          // if class borders are small, go for 1 decimal. otherwise, round to full integer
          let roundToInteger = false;
          if (!this.$store.state.quantiles && classSize >= 5) {
            roundToInteger = true;
          }
          if (i === 0) {
            thisBounds.min = roundToInteger ? Math.floor(thisBounds.min) : Math.floor(thisBounds.min * 10) / 10;
            thisBounds.max = precise_round(thisBounds.max, roundToInteger ? 0 : 1);
          } else if (i === numberOfClasses - 1) {
            thisBounds.max = roundToInteger ? Math.ceil(thisBounds.max) : Math.ceil(thisBounds.max * 10) / 10;
            thisBounds.min = precise_round(thisBounds.min, roundToInteger ? 0 : 1);
          } else {
            thisBounds.min = precise_round(thisBounds.min, roundToInteger ? 0 : 1);
            thisBounds.max = precise_round(thisBounds.max, roundToInteger ? 0 : 1);
          }
          classes.push(thisBounds);
          lastMaxValue = currentMax;
        }
        // for each class, calculate a color
        classes.forEach((c, i) => {
          const value = ((i + 1) * 100) / numberOfClasses;
          c.color = colorWithImpliedOpacity(this.mapColor, value / 100);
        });

        // craete legend for the UI
        this.$store.dispatch("legendItems", classes);
      }

      regionDataLayer.setStyle((feature) => {
        const lbfi = feature.get("lbfi");
        const styles = [];
        if (feature.get("layer") === layerByErg(this.erg) && this.filter(feature)) {
          const data = this.dataMode === 0 && this.jsonLoaded ? this.$store.state.data[lbfi] : null;
          const property =
            this.$store.state.attributeDarstellung == "Prozent" ? `${this.attribute}_Proz` : `${this.attribute}_Wert`;
          const value = data ? data[property] : NaN;
          let add2ndOutline = false;
          if (this.$store.state.selectedRegion && feature.get("lbfi") === this.$store.state.selectedRegion.lbfi) {
            stroke.setColor("rgba(0,0,0,.4)");
            stroke.setWidth(6);
            style.setZIndex(2);
            add2ndOutline = true;
          } else {
            stroke.setColor("#24312a");
            stroke.setWidth(1.5);
            style.setZIndex(1);
          }
          const zoom = Math.floor(map.getView().getZoom());
          if (value !== undefined || this.dataMode === 2) {
            style.setStroke(stroke);
            if (
              this.dataMode === 2 &&
              this.$store.state.tablesMissingBFIs &&
              this.$store.state.tablesMissingBFIs.includes(lbfi.toString())
            ) {
              // set pattern in table view mode
              style.getFill().setColor(lightPatternForZoom[zoom]);
            } else {
              style
                .getFill()
                .setColor(this.dataMode === 2 ? "rgba(255, 255, 255, 0.5)" : getColorForValue(classes, value, zoom));
            }
            styles.push(style);
            if (add2ndOutline) {
              styles.push(outlineStyle);
            }
          }
        }
        if (lbfi === 0) {
          styles.unshift(austriaStyle);
        }
        return styles;
      });
    }
  },
  computed: {
    tabLocked() {
      return this.noTablesErgListe.indexOf(this.erg) > -1 ? true : false;
    },
    glossaryItem() {
      let ret = null;
      if (this.$store.state.glossarShow !== null) {
        ret = true;
      }
      return ret;
    },
    /** @returns {Array<{value: string, name: string}>} */
    regionenListe() {
      const category = this.$route.params.datamode == "0" ? "Daten" : "Tabellen";
      //@ts-ignore
      return this.regionen(category);
    },
    isHierarchie3() {
      const category = this.$route.params.datamode == "0" ? "Daten" : "Tabellen";
      const metadata =
        category == "Daten" ? this.$store.state.metadata[this.erg] : this.$store.state.expertdata[this.erg];
      if (!metadata) {
        return false;
      }
      const cat3 = metadata.filter(
        (record) =>
          record.Hierarchie_1 == this.hierarchie1[category] &&
          record.Hierarchie_2 == this.hierarchie2[category] &&
          record.Hierarchie_3 != ""
      );

      return cat3.length > 0 ? true : false;
    },
    tabSelCol() {
      return this.dataModeString[this.$route.params.datamode];
    }
  },
  mounted() {}
};
</script>

<style>
.tabDiv {
  z-index: 1;
}

.tabDiv.closed {
  height: 40px;
  overflow: hidden;
}

.v-tab .tabContent {
  font-weight: normal;
  font-size: 13px !important;
  color: #fff;
  width: 100%;
  height: 43px;
  padding-top: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tabContent.tab1 {
  border-top-left-radius: 0px;
  background-color: #447681;
  box-shadow: inset -3px 2px 3px rgb(0 0 0 / 20%);
}
.tabContent.tab1.mobile {
  border-top-left-radius: 10px;
}

.tabContent.tab2 {
  background-color: #78a02c;
  box-shadow: inset 1px 2px 3px rgb(0 0 0 / 20%);
}

.tabContent.tab3 {
  background-color: #ac2a28;
  padding-top: 11px;
  box-shadow: inset 3px 2px 3px rgb(0 0 0 / 20%);
}

.mobileFilterSwitch {
  height: 40px;
  min-height: 40px;
  line-height: 40px;
}
.mobileFilterSwitch .v-image {
  display: inline-flex;
}

.selectHead {
  border-top: 1px solid #6a7b71;
  border-collapse: collapse;
  height: 46px;
  min-height: 46px !important;
  max-height: 46px !important;
  padding: 0px !important;
  margin: 0px !important;
}
.selectHead.bottom {
  border-bottom: 1px solid #6a7b71;
}
.selectHead.bottom.v-expansion-panel-header--active {
  border-bottom: none;
}

.selectHead:hover {
  border-top: 1px solid #6a7b71;
  border-bottom: 1px solid #6a7b71;
  padding-top: 1px !important;
}
.selectHead.bottom:hover {
  border-top: 1px solid #6a7b71;
  border-bottom: 2px solid #6a7b71;
}

.selectHead .label {
  position: absolute;
  left: 10px;
  top: 5px;
  font-size: 10px;
  color: #666;
}

.selectHead span {
  color: #6a7b71;
  font-size: 15px;
  margin-left: 10px;
  margin-top: 10px;
}

.v-expansion-panels.v-expansion-panels--flat.belowMinHeight {
  max-height: calc(100vh - 240px);
  max-height: calc(var(--vh, 1vh) * 100 - 240px);

  overflow-y: auto;
  overflow-x: hidden;
}

.v-expansion-panels.v-expansion-panels--flat.isMobile {
  max-height: calc(100vh - 187px);
  overflow-y: auto;
  overflow-x: hidden;
}

.selectContent {
  border-bottom: 1px solid #6a7b71;
  border-collapse: collapse;
  max-height: calc(var(--vh, 1vh) * 100 - 480px);
  overflow: auto;
}

.belowMinHeight .selectContent,
.isMobile .selectContent {
  max-height: none;
}

.v-expansion-panel-content.selectContent {
  display: none;
}

.v-expansion-panel--active.v-item--active .v-expansion-panel-content.selectContent {
  display: flex;
}

.v-expansion-panel-content__wrap {
  padding: 0px !important;
  margin: 0px !important;
}

.selectRow {
  height: 30px;
}
.selectRow .col span {
  line-height: 30px;
  width: calc(100% - 40px);
  display: inline-block;
  vertical-align: middle;
  color: #455a4e;
  font-size: 15px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.regionMarker {
  display: inline-block;
  text-align: center;
  padding: 1px;
  border-radius: 2px;
  margin-right: 4px;
  width: 22px;
  height: 14px;
  font-size: 9px;
  background-color: #ccc;
  color: #fff;
}
.regionMarker.active {
  background-color: #455a4e;
}

.markerLegend {
  display: inline-block;
  font-size: 11px;
  line-height: 11px;
  vertical-align: middle;
}

.glossaryShown {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 100;
  width: 100%;
  height: 100%;
}

.glossaryShown .greenBack {
  width: 100%;
  height: 100px;
  background-color: #447681;
  display: none;
}

.glossaryShown.startPage {
  background-color: white;
}

.glossaryShown.startPage .greenBack {
  display: block;
}

.glossaryShown.startPage .whiteInBar {
  position: relative;
  width: 100%;
  height: 68px;
  background-color: #fff;
  box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.3);
}

.glossaryShown.startPage .slogan {
  width: 100%;
  height: 32px;
  line-height: 32px;
  color: #fff;
  font-size: 13px;
  padding-right: 10px;
  text-align: right;
}

.shadowDropper {
  background-color: white;
  box-shadow: -1px 1px 4px 2px rgb(0 0 0 / 30%);
  margin-bottom: 0px;
}
.shadowDropper.mobile {
  width: calc(100vw - 210px);
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1000;
  box-shadow: none;
}

.scrollindicator.top {
  display: none;
  position: absolute;
  z-index: 10;
  left: 0px;
  top: 46px;
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 75%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

.isMobile .scrollindicator.top {
  display: none !important;
}

.selectContent.onTop .scrollindicator.top {
  display: block;
}

.scrollindicator.bottom {
  display: none;
  position: absolute;
  z-index: 10;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 75%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

.isMobile .scrollindicator.bottom {
  display: none !important;
}

.selectContent.onBottom .scrollindicator.bottom {
  display: block;
}

.hiddenPanel {
  display: none !important;
}

.searchContainer {
  height: 57px;
  max-height: 57px;
}

.shadowDropper.mobile .searchContainer {
  height: 56px;
  max-height: 56px;
}
</style>
