import axios from "redaxios";
import parseCsv from "csv-parse/lib/sync";

export default function (store) {
  axios("./data/glossar.txt").then((response) => {
    const sorted = parseCsv(response.data, {
      delimiter: ";",
      columns: true,
      trim: true
    });
    sorted.sort((a, b) => {
      let bA = a.Begriff.toLowerCase();
      let bB = b.Begriff.toLowerCase();
      bA = bA.replace(/ö/g, "oe");
      bA = bA.replace(/ä/g, "ae");
      bA = bA.replace(/ü/g, "ue");
      bB = bB.replace(/ö/g, "oe");
      bB = bB.replace(/ä/g, "ae");
      bB = bB.replace(/ü/g, "ue");
      return bA > bB ? 1 : -1;
    });
    store.dispatch("glossar", sorted);
  });
}
