<template>
  <div
    v-if="$store.state.imprintShow"
    class="imprintScreen"
    :class="{ onStart: typeof $route.params.datamode == 'undefined', mobile: $store.state.isMobile }"
  >
    <div class="imprintViewer">
      <v-row no-gutters class="pa-0 ma-0 imprintNavi">
        <v-col cols="10" class="pt-2"> </v-col>
        <v-col cols="2" class="pt-2" align="right">
          <v-icon title="Schließen" class="mr-2" @click="$store.state.imprintShow = false">mdi-close</v-icon>
        </v-col>
      </v-row>
      <div class="innerViewer pa-6" ref="innerViewer">
        <h1>Impressum</h1>

        <h2>Datenschutzerklärung</h2>
        <p>
          <a href="https://www.bfw.gv.at/datenschutzerklaerung" target="_blank"
            >Link zur Datenschutzerklärung des BFW</a
          >
        </p>
        <h2>Für den Inhalt verantwortlich</h2>
        <p>
          Bundesforschungs- und Ausbildungszentrum für Wald,<br />
          Naturgefahren und Landschaft, kurz BFW<br />
          Seckendorff-Gudent-Weg 8, A-1131 Wien
        </p>

        <h2>Eigentümer des BFW</h2>
        <p>
          Republik Österreich<br />
          Aufsichtsbehörde: Bundesministerium für Land- und Forstwirtschaft, Regionen und Wasserwirtschaft
        </p>

        <h2>Leiter des BFW</h2>
        <p>
          Dipl.-Ing. Dr. Peter Mayer<br />
          Tel. national: 01 – 878 38 – 0<br />
          Tel. international: +43 1 878 38 – 0
        </p>
        <p>
          Fax national: 01 – 878 38 – 1250<br />
          Fax international: +43 1 878 38 – 1250<br />
          E-Mail: direktion@bfw.gv.at
        </p>
        <h2>Bankverbindung / Firmenbuch</h2>
        <p>
          BIC BAWAATWW<br />
          IBAN: AT09 6000 0000 9605 0844<br />
          Bankleitzahl: 60000<br />
          Kontonummer: PSK 96.050.844<br />
          Firmenbuchgericht: Handelsgericht Wien , FN 257240 w<br />
          UID-Nummer: ATU 61289616<br />
          DVR: 0099384
        </p>

        <h2>Gegenstand des Unternehmens</h2>
        <p>
          Das Bundesforschungs- und Ausbildungszentrums für Wald, Naturgefahren und Landschaft (BFW) ist eine
          österreichische, multidisziplinäre Forschungs- und Ausbildungsstelle des Bundes in der Rechtsform „Anstalt
          öffentlichen Rechts“. Das BFW unterstützt die ökonomisch, ökologisch und sozial nachhaltige Entwicklung der
          Gesellschaft durch die Erarbeitung von wissenschaftlichen Grundlagen und durch Wissensvermittlung zur
          multifunktionalen Nutzung natürlicher Ressourcen. Das BFW dient unter anderem dem Bund als Forschungs-,
          Ausbildungs- und Weiterbildungsstelle sowie als Informations-, Koordinations- und Beratungsstelle in den
          Bereichen Wald, Naturgefahren und Landschaft. Der Aufgabenbereich ist im BFW-Gesetz (Teil des
          Agrarrechtsänderungsgesetzes) und Forstgesetz (Bundesgesetzblatt 83/2004) näher festgelegt.
        </p>

        <h2>Webdevelopment und Design</h2>
        <p>Technische Umsetzung und Konzept: <a href="https://w3geo.at" target="_blank">w3geo GmbH</a></p>
        <p>
          Grafisches Design: <a href="https://wagner-daniela.at" target="_blank">Wagner Daniela Design + Marketing</a>
        </p>

        <h2>Copyright</h2>
        <p>
          Das BFW ist Urheber und Werknutzungsberechtigter der zur Verfügung gestellten Information und Inhaber aller
          sonstigen damit zusammenhängenden Schutzrechte. Die Vervielfältigung von Texten und Daten einschließlich der
          (digitalen) Speicherung und Nutzung auf optischen und elektronischen Datenträgern darf nur mit vorheriger
          schriftlicher Zustimmung durch das BFW erfolgen. Die Verwendung der Texte und Daten einschließlich Einspeisung
          in Online-Dienste, Datenbanken oder Homepages durch unberechtigte Dritte sowie die Veränderung oder
          Verfälschung von Informationen ist untersagt. Der Download sowie der Ausdruck zum persönlichen, nicht
          kommerziellen Gebrauch sind jedoch gestattet.
        </p>

        <h2>Foto Credits</h2>
        <p>Headerbild Landingpage: unsplash.com</p>

        <h2>Haftung</h2>
        <p>
          Das BFW prüft und aktualisiert die Informationen auf seinen Webseiten ständig. Trotz aller Sorgfalt können
          sich die Daten inzwischen geändert haben. Eine Haftung oder Gewährleistung für die Aktualität, Richtigkeit und
          Vollständigkeit der zur Verfügung gestellten Informationen kann daher nicht übernommen werden.
        </p>
        <p>
          Des Weiteren behält sich das BFW vor, Änderungen oder Ergänzungen der bereitgestellten Informationen
          vorzunehmen.
        </p>
        <p>Den auf den Webseiten angebotenen Informationen kommt keine Rechtsverbindlichkeit zu.</p>
        <p>
          Die Webseiten des BFW können Links zu Webseiten anderer Anbieter enthalten, für die das BFW keine
          Verantwortung oder Haftung übernimmt.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheTableOverlay",
  data: () => ({}),
  created() {},
  mounted() {},
  updated() {},
  watch: {},
  computed: {},
  methods: {}
};
</script>

<style scoped>
.imprintScreen {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(69, 90, 78, 0.9);
  z-index: 5;
  padding: 0px;
  margin: 0px;
}

.imprintScreen.onStart,
.imprintScreen.mobile {
  position: fixed;
}

.imprintViewer {
  position: absolute;
  left: 20px;
  top: 20px;
  border-top: solid 40px white;
  width: calc(100% - 40px);
  height: calc(100% - 50px);
  background-color: #fff;
  box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.3);
}

.imprintScreen.onStart .imprintViewer {
  left: 40px;
  top: 120px;
  width: calc(100% - 80px);
  height: calc(100% - 140px);
}

.imprintScreen.mobile .imprintViewer {
  left: 10px;
  top: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}

.imprintViewer .innerViewer {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.imprintViewer .innerViewer h1 {
  font-weight: bold;
  color: #447681;
  font-size: 24px;
  margin-bottom: 20px;
}

.imprintViewer .innerViewer h2 {
  font-weight: 400;
  color: #6a7b71;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.imprintViewer .innerViewer p {
  font-size: 15px;
}
.imprintViewer .innerViewer a {
  text-decoration: none;
  color: #447681;
}

.imprintNavi {
  position: absolute;
  width: 100%;
  left: 0px;
  top: -40px;
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
</style>
