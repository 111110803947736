import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.waldwissen.length > 0 && !_vm.$store.state.isMobile)?_c('div',{staticClass:"pa-3 thewissenBox",class:{ solo: _vm.waldwissen.length < 2, mobile: _vm.$store.state.isMobile, collapsed: _vm.collapsed }},[(_vm.$store.state.isMobile && _vm.collapsed)?_c(VIcon,{staticClass:"mobileCollapse",on:{"click":function($event){_vm.collapsed = false}}},[_vm._v("mdi-information")]):_vm._e(),(!_vm.collapsed)?_c(VCarousel,{attrs:{"height":"100","hide-delimiter-background":"","show-arrows":false,"light":"","cycle":true,"interval":"7000","continuous":true},model:{value:(_vm.wwCounter),callback:function ($$v) {_vm.wwCounter=$$v},expression:"wwCounter"}},_vm._l((_vm.waldwissen),function(element,index){return _c(VCarouselItem,{key:index},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"wissenHeader mb-1",attrs:{"cols":"12"}},[(_vm.$store.state.isMobile && !_vm.collapsed)?_c(VIcon,{staticClass:"mobileCollapse",on:{"click":function($event){_vm.collapsed = true}}},[_vm._v("mdi-arrow-collapse")]):_vm._e(),_vm._v(" "+_vm._s(element.header)+" ")],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pa-0 wissenImage",attrs:{"cols":"3"}},[_c(VImg,{attrs:{"width":"80","height":"76","src":'data/' + element.bild,"contain":""}})],1),_c(VCol,{staticClass:"pa-0 pl-2",attrs:{"cols":"9"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pa-1 pt-0 wissenContent",attrs:{"cols":"12"}},[_vm._v(_vm._s(element ? element.text : ""))])],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pa-0 wissenLink",attrs:{"cols":"12"}},[(element.link != '')?_c('a',{attrs:{"href":element.link.substr(0, 4) != 'http' ? 'http://' + element.link : element.link,"target":"_blank"}},[_vm._v("... mehr erfahren")]):_vm._e()])],1)],1)],1)],1)}),1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }