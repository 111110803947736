<template>
  <v-app>
    <v-main>
      <TheHome></TheHome>
    </v-main>
  </v-app>
</template>

<script>
import TheHome from "./components/TheHome.vue";
export default {
  components: {
    TheHome
  },
  data: () => ({ drawer: null })
};
</script>

<style>
html {
  overflow: auto !important;
}

.v-tabs-bar {
  height: auto !important;
}

.ol-layerselect.ol-control,
.ol-attribution.ol-control,
.ol-zoom.ol-control {
  border-radius: 0px;
  z-index: 1;
}

.ol-layerselect.ol-control button,
.ol-attribution.ol-control button,
.ol-zoom.ol-control button {
  background-color: rgb(69, 90, 78);
  width: 20px;
  height: 20px;
  font-size: 14px;
}
.mobile .ol-layerselect.ol-control button,
.mobile .ol-attribution.ol-control button,
.mobile .ol-zoom.ol-control button {
  background-color: rgb(69, 90, 78);
  width: 26px;
  height: 26px;
  font-size: 18px;
}

.ol-attribution.ol-control button {
  font-size: 12px;
}

.ol-attribution.ol-control button:hover,
.ol-attribution.ol-control button:focus,
.ol-zoom.ol-control button:hover,
.ol-zoom.ol-control button:focus {
  background-color: rgba(69, 90, 78, 0.8);
}

.ol-control.ol-zoom {
  position: absolute;
  left: auto;
  top: auto;
  right: 20px;
  bottom: 95px;
}

.ol-control.ol-attribution {
  position: absolute;
  right: 20px;
  bottom: 65px;
  font-size: 12px;
}
.ol-control.ol-layerselect {
  position: absolute;
  right: 0px;
  bottom: 5px;
}

.mobile .ol-control.ol-zoom {
  position: absolute;
  left: auto;
  top: auto;
  right: 10px;
  bottom: 105px;
}
.mobile .ol-control.ol-layerselect {
  position: absolute;
  right: -10px;
  bottom: 5px;
}
.mobile .ol-control.ol-attribution {
  position: absolute;
  right: 10px;
  bottom: 70px;
}

@media only screen and (max-height: 380px) {
  .ol-zoom.ol-control {
    display: none;
  }
}

.v-expansion-panels,
.v-expansion-panel {
  border-radius: 0px !important;
}
</style>
