const zoomLevels = Array.from(Array(20).keys());
function createPatternForZoom(z, color) {
  // factor for distance between lines
  let patternfactor = (z - 5) / 3;
  if (patternfactor <= 0) {
    patternfactor = 1;
  }
  const pixelRatio = window.devicePixelRatio || 1;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const p = document.createElement("canvas");
  p.width = patternfactor * 9 * pixelRatio;
  p.height = patternfactor * 9 * pixelRatio;
  const pctx = p.getContext("2d");

  pctx.fillStyle = "rgba(255, 255, 255, 0.5)";
  pctx.fillRect(0, 0, p.width, p.height);

  const x0 = patternfactor * 9 * pixelRatio;
  const x1 = -pixelRatio;
  const y0 = -pixelRatio;
  const y1 = patternfactor * 9 * pixelRatio;
  const offset = patternfactor * 9 * pixelRatio;

  pctx.strokeStyle = color || "rgb(170, 170, 170)";
  pctx.lineWidth = pixelRatio;
  pctx.beginPath();
  pctx.moveTo(x0, y0);
  pctx.lineTo(x1, y1);
  pctx.moveTo(x0 - offset, y0);
  pctx.lineTo(x1 - offset, y1);
  pctx.moveTo(x0 + offset, y0);
  pctx.lineTo(x1 + offset, y1);
  pctx.stroke();

  return ctx.createPattern(p, "repeat");
}
/**
 * array of zoom patterns
 * index is the desired zoom level, lines become less dense for higher zoom levels
 * */
export const lightPatternForZoom = zoomLevels.map((z) => {
  return createPatternForZoom(z);
});

export const patternForZoom = zoomLevels.map((z) => {
  return createPatternForZoom(z, "black");
});

export function layerByErg(erg) {
  return erg + "_daten_bfi_outline";
}
/**
 * Calculates a color with implied opacity against white.
 * @param {Array<number>} color
 * @param {number} opacity
 * @returns {Array<number>}
 */
export function colorWithImpliedOpacity([r, g, b, a], opacity) {
  return [
    Math.round(r * opacity + (1 - opacity) * 255),
    Math.round(g * opacity + (1 - opacity) * 255),
    Math.round(b * opacity + (1 - opacity) * 255),
    a || 1
  ];
}

/**
 * round precisely to given decimals
 * @param {number} num
 * @param {number} decimals
 * @returns {number}
 */
export function precise_round(num, decimals) {
  return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

/**
 * returns a color or a canvas pattern for given class
 * @param {Array<*>} classes
 * @param {number} value
 * @param {number} zoom
 * @returns {*} pattern
 */
export function getColorForValue(classes, value, zoom) {
  for (let i = 0, ii = classes.length; i < ii; i++) {
    if (value < classes[i].max || (i === ii - 1 && value === classes[i].max)) {
      return classes[i].color;
    }
  }
  // return pattern for undefined values
  return patternForZoom[zoom];
}

/**
 * transforms hex color to rgb(a) color
 * @param {string} hex
 * @returns {Array<number>}
 */
export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16), 1] : [100, 100, 100, 1];
}
