<template>
  <v-row
    v-if="$store.state.attributeName && $route.name == 'Map'"
    no-gutters
    class="mapHeader"
    :class="{ mobile: $store.state.isMobile }"
    ><v-col v-if="Number($route.params.datamode) == 0" cols="12" align="right">
      <v-icon class="mr-1 mb-1" small color="white">mdi-home</v-icon>WALDINVENTUR: {{ $store.state.attributeName }} |
      {{ $store.state.ergs[$store.state.periode] }} | {{ $route.params.verwaltungsebene }}
    </v-col>
    <v-col v-if="Number($route.params.datamode) == 1" cols="12" align="right">
      <v-icon class="mr-1 mb-1" small color="white">mdi-home</v-icon>WALDINVENTUR: {{ $store.state.rasterLayer.name }}
    </v-col>
    <v-col v-if="Number($route.params.datamode) == 2" cols="12" align="right">
      <v-icon class="mr-1 mb-1" small color="white">mdi-home</v-icon>WALDINVENTUR TABELLEN:
      {{ $store.state.attributeName }} | {{ $store.state.ergs[$store.state.periode] }} |
      {{ $route.params.verwaltungsebene }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TheMapHeader",
  data: () => ({}),
  created() {},
  mounted() {},
  updated() {},
  watch: {},
  computed: {}
};
</script>

<style scoped>
.mapHeader {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 31px;
  color: #fff;
  font-size: 13px;
  padding: 1px;
  padding-right: 10px;
}

.mapHeader.mobile {
  bottom: 4px;
  line-height: 10px;
}
</style>
